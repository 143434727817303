// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TokenField_subTitle__3AL70 {\n  font-size: 20px;\n  font-weight: bold;\n  color: #dddddd;\n  margin: 24px 0; }\n\n.TokenField_rowBetween__1RIP4 {\n  margin-bottom: 0;\n  display: flex;\n  align-items: center;\n  justify-content: space-between; }\n  .TokenField_rowBetween__1RIP4 .TokenField_subTitle__3AL70 {\n    margin: 0; }\n\n.TokenField_logoURI__2zTvk {\n  width: 24px; }\n\n.TokenField_row__2kpJT {\n  display: flex;\n  align-items: center; }\n  .TokenField_row__2kpJT span {\n    margin-right: 10px; }\n", "",{"version":3,"sources":["webpack://src/pages/Admin/ManagementTokens/TokensField/TokenField.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,cAAc,EAAA;;AAGhB;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,8BAA8B,EAAA;EAJhC;IAMI,SAAS,EAAA;;AAIb;EACE,WAAW,EAAA;;AAGb;EACE,aAAa;EACb,mBAAmB,EAAA;EAFrB;IAII,kBAAkB,EAAA","sourcesContent":[".subTitle {\n  font-size: 20px;\n  font-weight: bold;\n  color: #dddddd;\n  margin: 24px 0;\n}\n\n.rowBetween {\n  margin-bottom: 0;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  .subTitle {\n    margin: 0;\n  }\n}\n\n.logoURI {\n  width: 24px;\n}\n\n.row {\n  display: flex;\n  align-items: center;\n  span {\n    margin-right: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subTitle": "TokenField_subTitle__3AL70",
	"rowBetween": "TokenField_rowBetween__1RIP4",
	"logoURI": "TokenField_logoURI__2zTvk",
	"row": "TokenField_row__2kpJT"
};
export default ___CSS_LOADER_EXPORT___;
