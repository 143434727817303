import { createSelector } from 'reselect'
import { initialState } from './slices'

export const selectReport = (state) => state.report || initialState

export const selectLoading = () =>
  createSelector(selectReport, (state) => state.loading)

export const makeSelectDevices = () =>
  createSelector(selectReport, (state) => state.devices)

export const makeSelectTokens = () =>
  createSelector(selectReport, (state) => state.tokens)

export const makeSelectChains = () =>
  createSelector(selectReport, (state) => state.chains)
