import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { Switch, useRouteMatch, Route } from 'react-router-dom'
import AdminPrivateRoute from 'routes/AdminPrivateRoute'
import Users from 'pages/Admin/Users'
import Report from 'pages/Admin/Report'
import ChangePassword from 'pages/Admin/ChangePassword'
// import ReportToken from 'pages/Admin/ReportToken'
// import ReportUSD from 'pages/Admin/ReportUSD'
// import ReportTradeVolume from 'pages/Admin/ReportTradeVolume'
// import ReportChainByUSD from 'pages/Admin/ReportChainByUSD'
import ReportChainByVolume from 'pages/Admin/ReportChainByVolume'
import ListingApplication from 'pages/Admin/ListingApplication'
import FaqCategory from 'pages/Admin/FaqCategory'
import Faq from 'pages/Admin/Faq'

import TokenBlacklist from 'pages/Admin/TokenBlacklist/TokenBlacklist'
import WalletAddressBlacklist from 'pages/Admin/WalletAddressBlacklist/WalletAddressBlacklist'
import ManagementTokens from 'pages/Admin/ManagementTokens/ManagementTokens'

import RevOps from 'pages/Admin/RevOps'

import Header from '../../components/Header'
import SideBar from '../../components/SideBar'
import classes from './AdminLayout.module.scss'

const AdminLayout = ({ location }) => {
  // console.log(props)
  const { path } = useRouteMatch()
  /**
   * state quản lý việc show menu hay không
   */
  const [showMenu, setShowMenu] = useState('FULL')
  const [noTransition, setNoTransition] = useState(true)
  const [showMenuClass, setShowMenuClass] = useState(false)

  useEffect(() => {
    setShowMenu(localStorage.getItem('showMenu') || 'FULL')
  }, [])

  /**
   * xử lý toggle menu
   */
  const handleToggleMenu = () => {
    let newShowMenu = null
    if (showMenu === 'FULL' || !showMenu) {
      newShowMenu = 'COLLAPSE'
    } else if (showMenu === 'COLLAPSE') {
      newShowMenu = 'FULL'
    }
    localStorage.setItem('showMenu', newShowMenu)
    setShowMenu(newShowMenu)
    setNoTransition(false)
  }

  /**
   * xử lý show menu
   */
  const handleShowMenuMobile = () => {
    setShowMenuClass(true)
  }

  /**
   * xử lý hide menu
   */
  const handleHideMenuMobile = () => {
    setShowMenu(false)
    setTimeout(() => {
      setShowMenuClass(false)
    }, 400)
  }

  return (
    <div
      className={classNames(classes.container, 'adminContainer')}
      id='outer-container'
    >
      <div
        className={classNames(
          classes.sideBarMobile,
          showMenuClass && classes.sideBarShow,
        )}
      >
        <SideBar
          mobile
          handleHideMenu={handleHideMenuMobile}
          screen={screen}
          showMenu
          pathname={location.pathname}
        />
      </div>
      {showMenuClass && (
        <div
          className={classNames('fade modal-backdrop show', classes.backdrop)}
        />
      )}
      <div
        className={classNames(
          classes.header,
          showMenu === 'COLLAPSE' && classes.headerCollapse,
        )}
      >
        <Header
          showMenu
          handleShowMenuMobile={handleShowMenuMobile}
          handleHideMenu={handleHideMenuMobile}
          handleToggleMenu={handleToggleMenu}
          collapse={showMenu === 'COLLAPSE'}
          showMenuClass={showMenuClass}
        />
      </div>
      <div className={classes.content}>
        <div
          className={classNames(
            classes.sideBar,
            showMenu === 'COLLAPSE' && classes.sideBarCollapseWrapper,
          )}
        >
          <SideBar
            screen={screen}
            showMenu={!!showMenu}
            collapse={showMenu === 'COLLAPSE'}
            handleToggleMenu={handleToggleMenu}
            pathname={location.pathname}
          />
        </div>
        <div
          className={classNames(
            classes.main,
            showMenu === 'FULL' && classes.showMenu,
            showMenu === 'COLLAPSE' && classes.showMenuCollapse,
            noTransition && classes.noTransition,
          )}
          id='page-wrap'
        >
          <Switch>
            <AdminPrivateRoute path={`${path}/users`} component={Users} exact />
            <AdminPrivateRoute path={`${path}/report`} component={Report} />
            <AdminPrivateRoute
              path={`${path}/faq/category`}
              component={FaqCategory}
            />
            <AdminPrivateRoute path={`${path}/faq/list`} component={Faq} />
            <AdminPrivateRoute path={`${path}/rev-ops`} component={RevOps} />
            <AdminPrivateRoute
              path={`${path}/change-password`}
              component={ChangePassword}
            />
            <Route
              path={`${path}/chain-by-usd`}
              render={(props) => <ReportChainByVolume {...props} type='USD' />}
            />
            <Route
              path={`${path}/chain-by-volume`}
              render={(props) => (
                <ReportChainByVolume {...props} type='VOLUME' />
              )}
            />
            <Route
              path={`${path}/chain-by-amount`}
              render={(props) => (
                <ReportChainByVolume {...props} type='AMOUNT' />
              )}
            />
            <AdminPrivateRoute
              path={`${path}/listing-application`}
              component={ListingApplication}
            />
            <AdminPrivateRoute
              path={`${path}/token-blacklist`}
              component={TokenBlacklist}
            />
            <AdminPrivateRoute
              path={`${path}/walletaddress-blacklist`}
              component={WalletAddressBlacklist}
            />
            <Route
              path={`${path}/safemoon-connect-tokens`}
              render={(props) => (
                <ManagementTokens {...props} type='SAFEMOON_CONNECT' />
              )}
            />
            <Route
              path={`${path}/safemoon-swap-tokens`}
              render={(props) => (
                <ManagementTokens {...props} type='SAFEMOON_SWAP' />
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default AdminLayout
