import React, { useEffect, useState } from 'react'
import Button from 'components/Button'
import { FormSection, Field, reduxForm, getFormValues } from 'redux-form'
import InputField from 'components/InputField'
import MultiInputField from 'components/MultiInputField'
import CropImageField from 'components/CropImageField'
import classNames from 'classnames'
import * as Api from 'api/api'
import { createStructuredSelector } from 'reselect'
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'react-bootstrap'
import editIcon from 'images/edit-white.svg'
import { showNotification } from 'layout/CommonLayout/actions'
import classes from './ManagementTokens.module.scss'
import TokensField from './TokensField/TokensField'
import Preview from './Preview'
import ConfirmSave from './ConfirmSave'

const mapStateToProps = createStructuredSelector({
  formState: (state) => getFormValues('ManagementTokens')(state) || {},
})

const Version = () => (
  <div className={classNames(classes.row, classes.version)}>
    <div className={classes.col}>
      <Field name='major' component={InputField} label='Major' />
    </div>
    <div className={classes.col}>
      <Field name='minor' component={InputField} label='Minor' />
    </div>
    <div className={classes.col}>
      <Field name='patch' component={InputField} label='Patch' />
    </div>
  </div>
)

const ManagementTokens = ({ initialize, type, invalid, touch }) => {
  const [loading, setLoading] = useState(false)
  const [preview, setPreview] = useState()
  const [showVersion, setShowVersion] = useState(false)
  const [showConfirmSave, setShowConfirmSave] = useState(false)

  const { formState } = useSelector(mapStateToProps)

  const dispatch = useDispatch()

  useEffect(() => {
    const getTokens = async () => {
      try {
        const result = await Api.marketData.get({
          url:
            type === 'SAFEMOON_CONNECT'
              ? '/api/swap/private/safemoon-connect/tokens'
              : '/api/swap/private/tokens',
        })
        initialize(result.data)
      } catch (e) {
        console.log(e)
      }
    }

    getTokens()
  }, [type])

  const handleSave = async () => {
    try {
      setLoading(true)

      await Api.marketData.put({
        url:
          type === 'SAFEMOON_CONNECT'
            ? '/api/swap/safemoon-connect/tokens'
            : '/api/swap/tokens',
        data: formState,
      })

      dispatch(
        showNotification({
          type: 'SUCCESS',
          message: 'Save tokens success!',
        }),
      )

      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const handlePreview = () => {
    setPreview(formState)
  }

  const handleShowConfirm = () => {
    if (invalid) {
      touch('logoURI', 'name', 'version', 'keywords', 'tokens')
      return
    }
    setShowConfirmSave(true)
  }

  return (
    <form
      className={classNames(classes.container, 'group groupBody')}
      // onSubmit={handleSubmit(handleSave)}
    >
      <div className={classes.head}>
        <h2 className={classes.title}>
          {type === 'SAFEMOON_CONNECT'
            ? 'Management Connect Tokens'
            : 'Management Swap Token'}
        </h2>
        <div className={classes.end}>
          <Button className='btn btnSecond mr20' onClick={handlePreview}>
            Preview
          </Button>
          <Button
            className='btn btnMain'
            loading={loading}
            type='button'
            onClick={handleShowConfirm}
          >
            Save
          </Button>
        </div>
      </div>
      <h3 className={classes.subTitle}>
        Version: {formState.version?.major}.{formState.version?.minor}.
        {formState.version?.patch}
        <a
          className={classes.btnEdit}
          onClick={() => setShowVersion((prev) => !prev)}
        >
          <img src={editIcon} className={classes.editIcon} alt='edit' />
        </a>
      </h3>
      {showVersion && (
        <FormSection name='version'>
          <Version />
        </FormSection>
      )}

      <h3 className={classes.subTitle}>Information</h3>
      <div className={classes.row}>
        <Field
          name='logoURI'
          component={CropImageField}
          label='Logo URI'
          width={300}
          height={300}
        />
        <div className={classes.right}>
          <Field name='name' component={InputField} label='Name' />

          <Field
            name='keywords'
            component={MultiInputField}
            label='Keywords'
            btnLabel='Add keyword'
          />
        </div>
      </div>

      <Field name='tokens' component={TokensField} />
      <Modal show={!!preview} onHide={() => setPreview()} size='xl'>
        <Preview handleClose={() => setPreview()} preview={preview} />
      </Modal>

      <Modal show={showConfirmSave} onHide={() => setShowConfirmSave(false)}>
        <ConfirmSave
          handleClose={() => setShowConfirmSave(false)}
          handleSave={handleSave}
        />
      </Modal>
    </form>
  )
}

const validate = (values) => {
  const errors = {}
  // console.log(values)
  if (!values.keywords || values.keywords.length === 0) {
    errors.keywords = 'Please add atleast keywords'
  }
  if (!values.tokens || values.tokens.length === 0) {
    errors.tokens = 'Please add atleast tokens'
  }
  return errors
}

export default reduxForm({
  form: 'ManagementTokens',
  validate,
})(ManagementTokens)
