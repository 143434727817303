import React, { useEffect, useState } from 'react'
import { useInjectReducer } from 'utils/injectReducer'
import { useInjectSaga } from 'utils/injectSaga'
import { createStructuredSelector } from 'reselect'
import { useSelector, useDispatch } from 'react-redux'
import Button from 'components/Button'
import moment from 'moment'
import * as Api from 'api/api'
import { showNotification } from 'layout/CommonLayout/actions'
import classes from './Report.module.scss'
import ReportDevices from './components/ReportDevices/ReportDevices'
import ChartWrapper from './components/ChartWrapper'
import BarChart from './components/BarChart'
import reducer, { getDevices, getTokens, getChains } from './slices'
import saga from './sagas'
import {
  makeSelectChains,
  makeSelectDevices,
  makeSelectTokens,
} from './selectors'

const mapStateToProps = createStructuredSelector({
  devices: makeSelectDevices(),
  tokens: makeSelectTokens(),
  chains: makeSelectChains(),
})

const Report = () => {
  const [loading, setLoading] = useState()
  useInjectReducer({ key: 'report', reducer })
  useInjectSaga({ key: 'report', saga })

  const dispatch = useDispatch()

  const { devices, chains, tokens } = useSelector(mapStateToProps)

  useEffect(() => {
    dispatch(getDevices())
    dispatch(getTokens({ day: 7 }))
    dispatch(getChains({ day: 7 }))
  }, [])

  const handleGetChains = (day) => {
    dispatch(getChains({ day }))
  }

  const handleGetToken = (day) => {
    dispatch(getTokens({ day }))
  }

  const handleUpdateData = async () => {
    try {
      setLoading(true)
      await Promise.all([
        Api.get({
          url: '/craw/devices',
        }),
        Api.get({
          url: '/craw/tokens',
          params: {
            date: moment().format('YYYY-MM-DD'),
          },
        }),
      ])
      dispatch(
        showNotification({
          type: 'SUCCESS',
          message: 'Get update data success',
        }),
      )
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.rowBetween}>
        <h2 className={classes.title}>Dashboard</h2>
        <Button
          className='btn btnMain'
          loading={loading}
          onClick={handleUpdateData}
        >
          Update report data now!
        </Button>
      </div>
      <div className={classes.row}>
        <div className={classes.reportDevice}>
          <ReportDevices devices={devices} />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.col}>
          <ChartWrapper
            title='Report by usd'
            handleGetToken={handleGetToken}
            data={tokens}
          />
        </div>
      </div>

      <div className={classes.byNumberTransfer}>
        <BarChart
          title='Report Chain'
          data={chains}
          handleGetChains={handleGetChains}
        />
      </div>
    </div>
  )
}

export default Report
