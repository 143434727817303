import { put, takeLatest, call } from 'redux-saga/effects'
import * as Api from 'api/api'
import moment from 'moment'
import { SYMBOLS } from 'utils/constants'
import {
  getTokensSuccess,
  GET_TOKENS,
  GET_CHAINS,
  getChainsSuccess,
  getDevicesSuccess,
  GET_DEVICES,
} from './slices'

export function* getTokens({ params }) {
  try {
    const { day = 7 } = params

    const fromDate = moment().add(-day, 'days').format('YYYY-MM-DD')
    const toDate = moment().format('YYYY-MM-DD')

    const result = yield call(Api.get, {
      url: '/report/tokens',
      params: {
        fromDate,
        toDate,
        pageSize: day,
      },
    })

    const prices = result.data.prices

    const data = {}
    const dataUSD = {}

    result.data.result.docs.reverse().forEach((item) => {
      const name = moment(item.date).format('MMM, DD')
      let totalUsd = 0
      SYMBOLS.forEach((symbol) => {
        if (data[symbol.value]) {
          const usd =
            +(item[symbol.value] ? item[symbol.value].amount : 0) *
            +(prices[symbol.value.toUpperCase()] || 0)
          data[symbol.value].push({
            name,
            val: item[symbol.value] ? item[symbol.value].numberOfTransfers : 0,
          })
          dataUSD[symbol.value].push({
            name,
            val: item[symbol.value] ? usd : 0,
          })
          totalUsd += usd
        } else {
          const usd =
            +(item[symbol.value] ? item[symbol.value].amount : 0) *
            +(prices[symbol.value.toUpperCase()] || 0)
          data[symbol.value] = [
            {
              name,
              val: item[symbol.value]
                ? item[symbol.value].numberOfTransfers
                : 0,
            },
          ]
          dataUSD[symbol.value] = [
            {
              name,
              val: item[symbol.value] ? usd : 0,
            },
          ]
          totalUsd += usd
        }
      })
      if (data.ALL) {
        data.ALL.push({
          name,
          val: item.numberOfTransfers || 0,
        })
        dataUSD.ALL.push({
          name,
          val: totalUsd || 0,
        })
      } else {
        data.ALL = [
          {
            name,
            val: item.numberOfTransfers || 0,
          },
        ]
        dataUSD.ALL = [
          {
            name,
            val: totalUsd || 0,
          },
        ]
      }
    })

    yield put(getTokensSuccess({ data, dataUSD }))
  } catch (e) {
    console.log('e', e)
  }
}

export function* getChains({ params }) {
  try {
    const { day = 7 } = params

    const fromDate = moment().add(-day, 'days').format('YYYY-MM-DD')
    const toDate = moment().format('YYYY-MM-DD')

    const result = yield call(Api.get, {
      url: '/report/chains',
      params: {
        fromDate,
        toDate,
        pageSize: day,
      },
    })

    const prices = result.data.prices

    const data = []
    const dataUSD = []

    result.data.result.docs.reverse().forEach((item) => {
      data.push({
        ...item,
        name: moment(item.date).format('MMM, DD'),
        BEP20: item.bep20NumberOfTransfers,
        ERC20: item.erc20NumberOfTransfers,
      })

      let totalBep20 = 0
      let totalErc20 = 0
      if (item.erc20) {
        Object.entries(item.erc20).forEach(([symbol, value]) => {
          const usd = +value.amount * +(prices[symbol.toUpperCase()] || 0)
          totalErc20 += usd
        })
      }
      if (item.bep20) {
        Object.entries(item.bep20).forEach(([symbol, value]) => {
          const usd = +value.amount * +(prices[symbol.toUpperCase()] || 0)
          totalBep20 += usd
        })
      }

      dataUSD.push({
        name: moment(item.date).format('MMM, DD'),
        BEP20: totalBep20,
        ERC20: totalErc20,
      })
    })

    yield put(getChainsSuccess({ data, dataUSD }))
  } catch (e) {
    console.log('e', e)
  }
}

export function* getDevices({ params }) {
  try {
    const result = yield call(Api.get, {
      url: '/report/devices',
      params: {
        ...params,
        pageSize: 20,
      },
    })

    yield put(getDevicesSuccess(result.data))
  } catch (e) {
    console.log('e', e)
  }
}

export default function* saga() {
  yield takeLatest(GET_DEVICES, getDevices)
  yield takeLatest(GET_TOKENS, getTokens)
  yield takeLatest(GET_CHAINS, getChains)
}
