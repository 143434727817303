// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommonLayout_notification__qxtyy {\n  position: fixed;\n  left: 50%;\n  z-index: 9999999;\n  text-align: center; }\n\n.CommonLayout_container__2Sg3Q {\n  width: 100%;\n  height: 100%; }\n", "",{"version":3,"sources":["webpack://src/layout/CommonLayout/CommonLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,gBAAgB;EAChB,kBAAkB,EAAA;;AAGpB;EACE,WAAW;EACX,YAAY,EAAA","sourcesContent":[".notification {\n  position: fixed;\n  left: 50%;\n  z-index: 9999999;\n  text-align: center;\n}\n\n.container {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": "CommonLayout_notification__qxtyy",
	"container": "CommonLayout_container__2Sg3Q"
};
export default ___CSS_LOADER_EXPORT___;
