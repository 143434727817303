import React from 'react'
import closeIcon from 'images/close.svg'
import ReactJson from 'react-json-view'
import classes from './Preview.module.scss'

const Preview = ({ handleClose, preview }) => (
  <div className={classes.container}>
    <div className={classes.head}>
      <h2 className={classes.title}>Preview</h2>
      <a className={classes.btnClose} onClick={handleClose}>
        <img src={closeIcon} className={classes.closeIcon} alt='closeIcon' />
      </a>
    </div>
    <div className={classes.content}>
      <ReactJson src={preview} />
    </div>
  </div>
)

export default Preview
