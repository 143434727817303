import React, { useState, useEffect } from 'react'
import { Field, reduxForm, getFormValues } from 'redux-form'
import InputField from 'components/InputField'
import Button from 'components/Button'
import closeIcon from 'images/close.svg'
import CropImageField from 'components/CropImageField'
import Web3 from 'web3'
import erc20 from 'utils/smartContract/erc20.json'
import { Bep20ChainId, BINANCE_RPC_URL, ETHER_RPC_URL } from 'utils/config'
import { createStructuredSelector } from 'reselect'
import { useSelector } from 'react-redux'
import classes from './TokenForm.module.scss'

let timeout

const mapStateToProps = createStructuredSelector({
  formState: (state) => getFormValues('TokenForm')(state) || {},
})

const TokenForm = ({
  handleSubmit,
  handleClose,
  selectedToken,
  handleSubmitToken,
  change,
}) => {
  const [showLogoField, setShowLogoField] = useState(false)
  const [loading, setLoading] = useState(false)

  const { formState } = useSelector(mapStateToProps)

  useEffect(() => {
    setTimeout(() => {
      setShowLogoField(true)
    }, 100)
  }, [])

  const handleChangeAddress = (address) => {
    clearTimeout(timeout)
    timeout = setTimeout(async () => {
      try {
        setLoading(true)
        const web3 = new Web3(
          new Web3.providers.HttpProvider(
            selectedToken.chainId === Bep20ChainId
              ? BINANCE_RPC_URL
              : ETHER_RPC_URL,
          ),
        )
        const erc20Contract = new web3.eth.Contract(erc20, address)
        const [tokenName, symbol, decimals] = await Promise.all([
          erc20Contract.methods.name().call(),
          erc20Contract.methods.symbol().call(),
          erc20Contract.methods.decimals().call(),
        ])
        change('name', tokenName)
        change('symbol', symbol)
        change('decimals', decimals)
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }, 600)
  }

  return (
    <form
      className={classes.container}
      onSubmit={handleSubmit(handleSubmitToken)}
    >
      <div className={classes.head}>
        <h2 className={classes.title}>
          {selectedToken && selectedToken.address
            ? 'Update token'
            : 'Add token'}
        </h2>
        <a className={classes.btnClose} onClick={handleClose}>
          <img src={closeIcon} className={classes.closeIcon} alt='closeIcon' />
        </a>
      </div>
      <div className={classes.content}>
        {showLogoField && (
          <Field
            name='logoURI'
            component={CropImageField}
            label='Token logo'
            width={200}
            height={200}
          />
        )}

        <Field
          name='address'
          component={InputField}
          label='Address'
          onChange={handleChangeAddress}
          loading={loading}
        />
        {formState.symbol && (
          <>
            <p className={classes.label}>Chain ID</p>
            <p className={classes.value}>{formState.chainId}</p>
          </>
        )}
        {formState.symbol && (
          <>
            <p className={classes.label}>Token symbol</p>
            <p className={classes.value}>{formState.symbol}</p>
          </>
        )}
        {formState.name && (
          <>
            <p className={classes.label}>Token name</p>
            <p className={classes.value}>{formState.name}</p>
          </>
        )}
        {formState.decimals && (
          <>
            <p className={classes.label}>Decimals</p>
            <p className={classes.value}>{formState.decimals}</p>
          </>
        )}
      </div>
      <div className={classes.actions}>
        <Button className='btn btnMain btnLarge' type='submit'>
          {selectedToken && selectedToken.address
            ? 'Update token'
            : 'Add token'}
        </Button>
      </div>
    </form>
  )
}

const validate = (values) => {
  const errors = {}
  console.log(values)
  if (!values.logoURI) {
    errors.logoURI = 'Please upload logo for token'
  }

  if (!values.address || !values.address.trim()) {
    errors.address = 'Please enter address'
  } else if (
    !values.symbol ||
    !values.symbol.trim() ||
    !values.name ||
    !values.name.trim() ||
    !values.decimals
  ) {
    errors.address = 'Please enter valid address'
  }

  return errors
}

export default reduxForm({
  form: 'TokenForm',
  validate,
  enableReinitialize: true,
})(TokenForm)
