import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Dropdown } from 'react-bootstrap'
import defaultAvatarIcon from 'images/defaultAvatar.svg'
import classNames from 'classnames'
import logoIcon from 'images/logo.svg'
import history from 'utils/history'
import classes from './Header.module.scss'
import MenuButton from './MenuButton'

export default class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userInfo: {},
    }
  }

  componentDidMount() {
    this.setState({
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
    })
  }

  handleLogout = () => {
    localStorage.clear()
    history.push('/auth/login')
  }

  handleToogleMenu = () => {
    const { showMenuClass, handleShowMenuMobile, handleHideMenu } = this.props
    if (showMenuClass) {
      handleHideMenu()
    } else {
      handleShowMenuMobile()
    }
  }

  render() {
    const { handleToggleMenu, collapse, showMenuClass } = this.props

    const { userInfo } = this.state
    return (
      <div className={classes.container}>
        <div
          className={classNames(classes.left, collapse && classes.leftCollapse)}
        >
          <div className={classes.row}>
            <img src={logoIcon} className={classes.logoIcon} alt='logoIcon' />
            <p className={classes.safemoon}>SAFEMOON</p>
          </div>
          {handleToggleMenu && (
            <MenuButton handleToggleMenu={handleToggleMenu} active={collapse} />
          )}
        </div>
        <div className={classes.right}>
          <Dropdown>
            <Dropdown.Toggle
              variant='success'
              id='dropdown-basic'
              as={React.forwardRef(({ onClick }, ref) => (
                <div
                  className={classes.userName}
                  ref={ref}
                  onClick={(e) => {
                    e.preventDefault()
                    onClick(e)
                  }}
                >
                  <img
                    className={classes.avatar}
                    src={defaultAvatarIcon}
                    alt='avatar'
                  />
                  <div className={classes.info}>
                    <p className={classes.name}>{`${userInfo.firstName || ''} ${
                      userInfo.lastName || ''
                    }`}</p>
                    <p className={classes.accountType}>Admin</p>
                  </div>
                </div>
              ))}
            />

            <Dropdown.Menu>
              {/* <Dropdown.Item>
                <span
                  className={classes.dropdownItem}
                  onClick={() => {
                    history.push('/admin/change-password')
                  }}
                >
                  Change password
                </span>
              </Dropdown.Item> */}
              <Dropdown.Item>
                <span
                  className={classes.dropdownItem}
                  onClick={this.handleLogout}
                >
                  <FormattedMessage
                    id='Header.logout'
                    defaultMessage='Logout'
                  />
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className={classes.headerMenu}>
          <MenuButton
            handleToggleMenu={this.handleToogleMenu}
            active={showMenuClass}
          />

          <img src={logoIcon} className={classes.logoIcon} alt='logo' />

          <a className={classes.logout} onClick={this.handleLogout}>
            <FormattedMessage id='Header.logout' defaultMessage='Logout' />
          </a>
        </div>
      </div>
    )
  }
}
