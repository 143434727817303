import {
  SHOW_CONFIRM,
  HIDE_CONFIRM,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
} from './constants'

export const showNotification = (notification) => ({
  type: SHOW_NOTIFICATION,
  notification,
})

export const hideNotification = () => ({
  type: HIDE_NOTIFICATION,
})

export const handleShowConfirm = (confirm) => ({
  type: SHOW_CONFIRM,
  confirm,
})

export const handleHideConfirm = () => ({
  type: HIDE_CONFIRM,
})
