// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Table_isDragging__ve_l2 {\n  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16);\n  background-color: rgba(52, 58, 63, 0.5); }\n", "",{"version":3,"sources":["webpack://src/components/Table/Table.module.scss"],"names":[],"mappings":"AAAA;EAEE,6CAA6C;EAC7C,uCAAuC,EAAA","sourcesContent":[".isDragging {\n  // background-color: red;\n  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16);\n  background-color: rgba(52, 58, 63, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"isDragging": "Table_isDragging__ve_l2"
};
export default ___CSS_LOADER_EXPORT___;
