import React from 'react'
import Filter from 'components/Filter'
import Table from 'components/Table'
import GooglePaging from 'components/GooglePaging'
import { DragDropContext } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { handleShowConfirm } from 'layout/CommonLayout/actions'
import classes from './AdminPage.module.scss'

const AdminPage = ({
  filter,
  handleSearch,
  data,
  filterOptions,
  tableOptions,
  handleAddNew,
  handleUpdateData,
  handleReorder,
  isReorder,
}) => {
  const dispatch = useDispatch()
  const onDragEnd = (result) => {
    // dropped outside the list
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return
    }

    dispatch(
      handleShowConfirm({
        title: 'Confirm',
        description: 'Do you want reorder?',
        handleOk: () => {
          console.log(data.docs)
          const newLsData = [...data.docs]
          const oldData = newLsData.splice(result.source.index, 1)
          newLsData.splice(result.destination.index, 0, oldData[0])
          console.log(newLsData)
          const numberDocs = data.docs.length - 1
          let newOrder
          if (result.source.index === 0) {
            newOrder = data.docs[0].order / 2
          } else if (result.source.index === numberDocs) {
            const nextOrder =
              (Math.floor(data.docs[numberDocs].order / 1000) + 1) * 1000
            newOrder = (nextOrder - data.docs[numberDocs].order) / 2
          } else {
            newOrder =
              (data.docs[result.source.index + 1].order -
                data.docs[result.source.index - 1].order) /
              2
          }
          if (handleReorder) {
            handleReorder(newLsData[result.destination.index], newOrder)
          }

          console.log(newOrder)
          if (handleUpdateData) {
            handleUpdateData({
              ...data,
              docs: newLsData,
            })
          }
        },
      }),
    )
  }

  return (
    <div className='group'>
      <div className='groupBody'>
        <div className={classes.filter}>
          <Filter
            filter={filter}
            handleSearch={handleSearch}
            options={filterOptions}
          />
        </div>

        <div className={classes.head}>
          <p className={classes.title}>Total: {data?.totalElements}</p>

          {handleAddNew && (
            <a className='btn btnMain btnSmall' onClick={handleAddNew}>
              Add new
            </a>
          )}
        </div>

        <div className={classes.table}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Table
              data={data?.docs}
              tableOptions={tableOptions}
              filter={filter}
              handleSearch={handleSearch}
              isReorder={isReorder}
            />
          </DragDropContext>
        </div>

        <div className={classes.pagings}>
          <GooglePaging
            pageInfo={{
              page: data?.page,
              totalPages: data?.totalPages,
            }}
            changePage={({ page }) => handleSearch({ page })}
          />
        </div>
      </div>
    </div>
  )
}

export default AdminPage
