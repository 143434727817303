// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TokenBlacklist_container__1ADWT .TokenBlacklist_head__1F5Wl {\n  display: flex;\n  align-items: center;\n  justify-content: space-between; }\n  .TokenBlacklist_container__1ADWT .TokenBlacklist_head__1F5Wl .TokenBlacklist_title__3DAg8 {\n    font-size: 18px;\n    font-weight: bold;\n    color: #aaaaaa;\n    margin-bottom: 0; }\n    @media (max-width: 767px) {\n      .TokenBlacklist_container__1ADWT .TokenBlacklist_head__1F5Wl .TokenBlacklist_title__3DAg8 {\n        padding: 0 20px; } }\n\n.TokenBlacklist_container__1ADWT .TokenBlacklist_filter__UT1ZQ {\n  margin-bottom: 20px;\n  width: 100%; }\n", "",{"version":3,"sources":["webpack://src/pages/Admin/TokenBlacklist/TokenBlacklist.module.scss"],"names":[],"mappings":"AAAA;EAEI,aAAa;EACb,mBAAmB;EACnB,8BAA8B,EAAA;EAJlC;IAMM,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,gBAAgB,EAAA;IAChB;MAVN;QAWQ,eAAe,EAAA,EAElB;;AAbL;EAiBI,mBAAmB;EACnB,WAAW,EAAA","sourcesContent":[".container {\n  .head {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    .title {\n      font-size: 18px;\n      font-weight: bold;\n      color: #aaaaaa;\n      margin-bottom: 0;\n      @media (max-width: 767px) {\n        padding: 0 20px;\n      }\n    }\n  }\n  \n  .filter {\n    margin-bottom: 20px;\n    width: 100%;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TokenBlacklist_container__1ADWT",
	"head": "TokenBlacklist_head__1F5Wl",
	"title": "TokenBlacklist_title__3DAg8",
	"filter": "TokenBlacklist_filter__UT1ZQ"
};
export default ___CSS_LOADER_EXPORT___;
