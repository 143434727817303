// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WalletAddressBlacklist_container__2UbyT .WalletAddressBlacklist_head__309KB {\n  display: flex;\n  align-items: center;\n  justify-content: space-between; }\n  .WalletAddressBlacklist_container__2UbyT .WalletAddressBlacklist_head__309KB .WalletAddressBlacklist_title__2FDGN {\n    font-size: 18px;\n    font-weight: bold;\n    color: #aaaaaa;\n    margin-bottom: 0; }\n    @media (max-width: 767px) {\n      .WalletAddressBlacklist_container__2UbyT .WalletAddressBlacklist_head__309KB .WalletAddressBlacklist_title__2FDGN {\n        padding: 0 20px; } }\n\n.WalletAddressBlacklist_container__2UbyT .WalletAddressBlacklist_filter__gW_cm {\n  margin-bottom: 20px;\n  width: 100%; }\n", "",{"version":3,"sources":["webpack://src/pages/Admin/WalletAddressBlacklist/WalletAddressBlacklist.module.scss"],"names":[],"mappings":"AAAA;EAEI,aAAa;EACb,mBAAmB;EACnB,8BAA8B,EAAA;EAJlC;IAMM,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,gBAAgB,EAAA;IAChB;MAVN;QAWQ,eAAe,EAAA,EAElB;;AAbL;EAiBI,mBAAmB;EACnB,WAAW,EAAA","sourcesContent":[".container {\n  .head {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    .title {\n      font-size: 18px;\n      font-weight: bold;\n      color: #aaaaaa;\n      margin-bottom: 0;\n      @media (max-width: 767px) {\n        padding: 0 20px;\n      }\n    }\n  }\n  \n  .filter {\n    margin-bottom: 20px;\n    width: 100%;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "WalletAddressBlacklist_container__2UbyT",
	"head": "WalletAddressBlacklist_head__309KB",
	"title": "WalletAddressBlacklist_title__2FDGN",
	"filter": "WalletAddressBlacklist_filter__gW_cm"
};
export default ___CSS_LOADER_EXPORT___;
