import React from 'react'
import classNames from 'classnames'
import SelectField from 'components/SelectField'
import SearchComponent from 'components/SearchComponent'
import DatePickerFormTo from 'components/DatePickerFormTo'
import moment from 'moment'
import MultiSelectOptionsField from 'components/MultiSelectOptionsField'
import classes from './Filter.module.scss'

const Filter = (props) => {
  const { filter, handleSearch, options } = props
  return (
    <div className={classNames(classes.container, 'filterContainer')}>
      {options &&
        options.map((option) => (
          <div
            key={option.name}
            className={classNames(classes.option, option.className)}
          >
            {option.type === 'SEARCH' && (
              <div className={classes.search}>
                <SearchComponent
                  placeholder={option.label}
                  value={filter[option.name]}
                  handleSearch={(text) => handleSearch({ [option.name]: text })}
                />
              </div>
            )}

            {option.type === 'RANGER_DATE' && (
              <div className={classes.date}>
                <DatePickerFormTo
                  input={{
                    value: filter[option.name],
                    onChange: (date) => {
                      handleSearch({
                        fromDate:
                          date.from &&
                          moment(date.from, 'MM/DD/YYYY').format('YYYY-MM-DD'),
                        toDate:
                          date.to &&
                          moment(date.to, 'MM/DD/YYYY').format('YYYY-MM-DD'),
                      })
                    },
                  }}
                />
              </div>
            )}

            {option.type === 'SELECT' && (
              <div className={classNames(classes.select, options.className)}>
                <SelectField
                  options={option.options}
                  placeholder={option.label}
                  isClearable
                  input={{
                    value: filter[option.name],
                    onChange: (value) => {
                      handleSearch({ [option.name]: value?.value })
                    },
                  }}
                />
              </div>
            )}

            {option.type === 'MULTI_SELECT' && (
              <div
                className={classNames(classes.multiSelect, options.className)}
              >
                <MultiSelectOptionsField
                  options={option.options}
                  placeholder={option.label}
                  isClearable
                  input={{
                    value: filter[option.name],
                    onChange: (value) => {
                      const val =
                        value && value.map((item) => item.value).join(',')
                      handleSearch({ [option.name]: val })
                    },
                  }}
                />
              </div>
            )}
          </div>
        ))}
    </div>
  )
}

export default Filter
