import React, { useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/InputField'
import Button from 'components/Button'
import * as Api from 'api/api'
import closeIcon from 'images/close.svg'
import TextAreaField from 'components/TextAreaField'
import classes from './Form.module.scss'

const Form = ({
  handleSubmit,
  handleClose,
  handleRefreshData,
  selectedToken,
}) => {
  const [loading, setLoading] = useState(false)

  const handleSubmitUser = async (values) => {
    try {
      setLoading(true)

      const userInfo = JSON.parse(localStorage.getItem('userInfo'))

      if (values._id) {
        await Api.marketData.put({
          url: `/api/swap/token-blacklist`,
          data: {
            ...values,
            updatedBy: {
              userId: userInfo.userId,
              email: userInfo.email,
            },
          },
        })
      } else {
        await Api.marketData.post({
          url: `/api/swap/token-blacklist`,
          data: {
            ...values,
            blocker: {
              userId: userInfo.userId,
              email: userInfo.email,
            },
          },
        })
      }
      setLoading(false)
      handleRefreshData()
      handleClose()
    } catch (e) {
      setLoading(false)
    }
  }

  return (
    <form
      className={classes.container}
      onSubmit={handleSubmit(handleSubmitUser)}
    >
      <div className={classes.head}>
        <h2 className={classes.title}>
          {selectedToken && selectedToken._id
            ? 'Update token blacklist'
            : 'Create token blacklist'}
        </h2>
        <a className={classes.btnClose} onClick={handleClose}>
          <img src={closeIcon} className={classes.closeIcon} alt='closeIcon' />
        </a>
      </div>
      <div className={classes.content}>
        <Field
          name='tokenContractAddress'
          component={InputField}
          label='Token contract address'
          disabled={selectedToken && selectedToken._id}
        />

        <Field
          name='blockReason'
          component={TextAreaField}
          label='Block reason'
        />
      </div>
      <div className={classes.actions}>
        <Button
          className='btn btnMain btnLarge'
          loading={loading}
          type='submit'
        >
          {selectedToken && selectedToken._id
            ? 'Update token blacklist'
            : 'Create token blacklist'}
        </Button>
      </div>
    </form>
  )
}

const validate = (values) => {
  const errors = {}

  if (!values.token || !values.token.trim()) {
    errors.token = 'Please enter token'
  }

  if (!values.blockReason || !values.blockReason.trim()) {
    errors.blockReason = 'Please enter block reason'
  }

  return errors
}

export default reduxForm({
  form: 'TokenBlacklistForm',
  validate,
  enableReinitialize: true,
})(Form)
