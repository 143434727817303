import { put, takeLatest, call } from 'redux-saga/effects'
import * as Api from 'api/api'
import {
  getTokenBlacklistSuccess,
  getTokenBlacklistFailure,
  GET_TOKEN_BLACKLIST,
} from './slices'

export function* getTokenBlacklist() {
  try {
    const result = yield call(Api.marketData.get, {
      url: '/api/swap/token-blacklist',
    })

    yield put(
      getTokenBlacklistSuccess({
        docs: result.data,
        page: 1,
        totalElements: result.data.length,
        totalPages: 1,
      }),
    )
  } catch (e) {
    yield put(getTokenBlacklistFailure())
  }
}

export default function* saga() {
  yield takeLatest(GET_TOKEN_BLACKLIST, getTokenBlacklist)
}
