export const GET_DEVICES = 'app/report/getDevices'
export const GET_DEVICES_SUCCESS = 'app/report/getDevicesSuccess'

export const GET_TOKENS = 'app/report/getTokens'
export const GET_TOKENS_SUCCESS = 'app/report/getTokensSuccess'

export const GET_CHAINS = 'app/report/getChains'
export const GET_CHAINS_SUCCESS = 'app/report/getChainsSuccess'

export const getDevices = () => ({
  type: GET_DEVICES,
})

export const getDevicesSuccess = (devices) => ({
  type: GET_DEVICES_SUCCESS,
  devices,
})

export const getTokens = (params) => ({
  type: GET_TOKENS,
  params,
})

export const getTokensSuccess = (tokens) => ({
  type: GET_TOKENS_SUCCESS,
  tokens,
})

export const getChains = (params) => ({
  type: GET_CHAINS,
  params,
})

export const getChainsSuccess = (chains) => ({
  type: GET_CHAINS_SUCCESS,
  chains,
})

export const initialState = {
  chains: [],
  tokens: [],
  devices: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_DEVICES_SUCCESS:
      return {
        ...state,
        devices: action.devices,
      }
    case GET_TOKENS_SUCCESS:
      return {
        ...state,
        tokens: action.tokens,
      }
    case GET_CHAINS_SUCCESS:
      return {
        ...state,
        chains: action.chains,
      }
    default:
      return state
  }
}
