// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Report_rowBetween__2yE_Z {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 20px; }\n  .Report_rowBetween__2yE_Z .Report_title__Ltb9C {\n    font-size: 32px;\n    font-weight: bold;\n    color: #ffffff;\n    margin-bottom: 0; }\n  @media (max-width: 767px) {\n    .Report_rowBetween__2yE_Z {\n      flex-direction: column; }\n      .Report_rowBetween__2yE_Z .Report_title__Ltb9C {\n        margin-bottom: 10px; } }\n", "",{"version":3,"sources":["webpack://src/pages/Admin/Report/Report.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB,EAAA;EAJrB;IAMI,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,gBAAgB,EAAA;EAGlB;IAZF;MAaI,sBAAsB,EAAA;MAb1B;QAeM,mBAAmB,EAAA,EACpB","sourcesContent":[".rowBetween {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 20px;\n  .title {\n    font-size: 32px;\n    font-weight: bold;\n    color: #ffffff;\n    margin-bottom: 0;\n  }\n\n  @media (max-width: 767px) {\n    flex-direction: column;\n    .title {\n      margin-bottom: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rowBetween": "Report_rowBetween__2yE_Z",
	"title": "Report_title__Ltb9C"
};
export default ___CSS_LOADER_EXPORT___;
