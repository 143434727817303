// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DatePickerFormTo_container__36Pdh {\n  display: flex;\n  align-items: center; }\n  .DatePickerFormTo_container__36Pdh .DatePickerFormTo_col__15lM5 {\n    flex: 1 1; }\n  .DatePickerFormTo_container__36Pdh .DatePickerFormTo_arrow__1dchY {\n    width: 16px;\n    transform: rotate(180deg);\n    margin: 0 8px;\n    opacity: 0.6; }\n", "",{"version":3,"sources":["webpack://src/components/DatePickerFormTo/DatePickerFormTo.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB,EAAA;EAFrB;IAII,SAAO,EAAA;EAJX;IAOI,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,YAAY,EAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  .col {\n    flex: 1;\n  }\n  .arrow {\n    width: 16px;\n    transform: rotate(180deg);\n    margin: 0 8px;\n    opacity: 0.6;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "DatePickerFormTo_container__36Pdh",
	"col": "DatePickerFormTo_col__15lM5",
	"arrow": "DatePickerFormTo_arrow__1dchY"
};
export default ___CSS_LOADER_EXPORT___;
