// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TabField_container__-Q4qd {\n  display: flex;\n  align-items: center;\n  border-radius: 4px;\n  overflow: hidden;\n  background-color: #d7d8d9; }\n  .TabField_container__-Q4qd .TabField_option__23bsq {\n    flex: 1 1;\n    height: 40px;\n    background-color: #f8f8f8;\n    font-size: 14px;\n    font-weight: 500;\n    color: #6e7580;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer; }\n  .TabField_container__-Q4qd .TabField_active__3ZFxs {\n    background-color: #008a81;\n    color: #ffffff;\n    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);\n    border-radius: 4px; }\n", "",{"version":3,"sources":["webpack://src/components/TabField/TabField.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB,EAAA;EAL3B;IAOI,SAAO;IACP,YAAY;IACZ,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe,EAAA;EAhBnB;IAmBI,yBAAyB;IACzB,cAAc;IACd,2CAA2C;IAC3C,kBAAkB,EAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  border-radius: 4px;\n  overflow: hidden;\n  background-color: #d7d8d9;\n  .option {\n    flex: 1;\n    height: 40px;\n    background-color: #f8f8f8;\n    font-size: 14px;\n    font-weight: 500;\n    color: #6e7580;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n  }\n  .active {\n    background-color: #008a81;\n    color: #ffffff;\n    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);\n    border-radius: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TabField_container__-Q4qd",
	"option": "TabField_option__23bsq",
	"active": "TabField_active__3ZFxs"
};
export default ___CSS_LOADER_EXPORT___;
