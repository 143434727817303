import React, { Component } from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import userIcon from 'images/sidebar/users.svg'
import dashboard from 'images/sidebar/dashboard.svg'
import amount from 'images/sidebar/amount.svg'
import transfer from 'images/sidebar/transfer.svg'
import swap from 'images/sidebar/swap.svg'
import faq from 'images/sidebar/faq.svg'
import USD from 'images/sidebar/USD.svg'

// import reportIcon from 'images/sidebar/analytics.svg'
import settingsIcon from 'images/sidebar/settings.svg'
import ExpandMenus from './ExpandMenus'

import classes from './SideBar.module.scss'

export default class SideBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openMenus: [localStorage.getItem('openMenus')],
      pageRoles: localStorage.getItem('pageRoles')
        ? JSON.parse(localStorage.getItem('pageRoles'))
        : {},
      showMenus: localStorage.getItem('showMenus')
        ? JSON.parse(localStorage.getItem('showMenus'))
        : {},
    }
  }

  handleToggleMenu = (menu) => {
    this.setState((prevState) => {
      if (prevState.openMenus.indexOf(menu) !== -1) {
        localStorage.setItem('openMenus', '')
        return {
          ...prevState,
          openMenus: [],
        }
      }
      localStorage.setItem('openMenus', menu)
      return {
        ...prevState,
        openMenus: [menu],
      }
    })
  }

  render() {
    const { collapse, pathname } = this.props

    const { openMenus } = this.state

    return (
      <PerfectScrollbar
        className={classNames(classes.container, collapse && classes.collapse)}
        options={{ wheelPropagation: false }}
      >
        <div className={classes.menuContainer}>
          {/* <div className={classes.heading}>SafeMoon Wallet</div>
          <div className={classes.divider} /> */}

          <div className={classes.heading}>SafeMoon Wallet</div>
          <div className={classes.divider} />

          <NavLink
            to='/admin/report'
            className={classNames(classes.menuItem)}
            activeClassName={classes.active}
          >
            <div className={classes.iconWrapper}>
              <img
                src={dashboard}
                alt='bao-cao.svg'
                className={classes.reportIcon}
              />
            </div>
            <span>Dashboard</span>
          </NavLink>

          <NavLink
            to='/admin/chain-by-amount'
            className={classNames(classes.menuItem)}
            activeClassName={classes.active}
          >
            <div className={classes.iconWrapper}>
              <img
                src={amount}
                alt='bao-cao.svg'
                className={classes.reportIcon}
              />
            </div>
            <span>Transfers by (Amount)</span>
          </NavLink>

          <NavLink
            to='/admin/chain-by-volume'
            className={classNames(classes.menuItem)}
            activeClassName={classes.active}
          >
            <div className={classes.iconWrapper}>
              <img
                src={transfer}
                alt='bao-cao.svg'
                className={classes.reportIcon}
              />
            </div>
            <span>Transfers by (Quantity)</span>
          </NavLink>

          <NavLink
            to='/admin/chain-by-usd'
            className={classNames(classes.menuItem)}
            activeClassName={classes.active}
          >
            <div className={classes.iconWrapper}>
              <img src={USD} alt='bao-cao.svg' className={classes.reportIcon} />
            </div>
            <span>Transfers by (USD Value)</span>
          </NavLink>

          <NavLink
            to='/admin/token-blacklist'
            className={classNames(classes.menuItem)}
            activeClassName={classes.active}
          >
            <div className={classes.iconWrapper}>
              <img
                src={settingsIcon}
                alt='bao-cao.svg'
                className={classes.reportIcon}
              />
            </div>
            <span>Token blacklist</span>
          </NavLink>

          <NavLink
            to='/admin/walletaddress-blacklist'
            className={classNames(classes.menuItem)}
            activeClassName={classes.active}
          >
            <div className={classes.iconWrapper}>
              <img
                src={settingsIcon}
                alt='bao-cao.svg'
                className={classes.reportIcon}
              />
            </div>
            <span>Wallet address blacklist</span>
          </NavLink>

          <div className={classes.heading}>SafeMoon Swap</div>
          <div className={classes.divider} />

          <NavLink
            exact
            to='/admin/rev-ops'
            className={classNames(classes.menuItem)}
            activeClassName={classes.active}
          >
            <div className={classes.iconWrapper}>
              <img
                src={dashboard}
                alt='bao-cao.svg'
                className={classes.reportIcon}
              />
            </div>
            <span>RevOps Reports</span>
          </NavLink>

          <NavLink
            exact
            to='/admin/rev-ops'
            className={classNames(classes.menuItem)}
            activeClassName={classes.active}
          >
            <div className={classes.iconWrapper}>
              <img
                src={dashboard}
                alt='bao-cao.svg'
                className={classes.reportIcon}
              />
            </div>
            <span>RevOps Reports</span>
          </NavLink>

          <NavLink
            to='/admin/listing-application'
            className={classNames(classes.menuItem)}
            activeClassName={classes.active}
          >
            <div className={classes.iconWrapper}>
              <img
                src={swap}
                alt='bao-cao.svg'
                className={classes.reportIcon}
              />
            </div>
            <span>Swap Applications</span>
          </NavLink>

          <NavLink
            to='/admin/safemoon-swap-tokens'
            className={classNames(classes.menuItem)}
            activeClassName={classes.active}
          >
            <div className={classes.iconWrapper}>
              <img
                src={amount}
                alt='bao-cao.svg'
                className={classes.reportIcon}
              />
            </div>
            <span>Manage swap tokens</span>
          </NavLink>

          <div className={classes.heading}>SafeMoon Connect</div>

          <div className={classes.divider} />
          <NavLink
            to='/admin/safemoon-connect-tokens'
            className={classNames(classes.menuItem)}
            activeClassName={classes.active}
          >
            <div className={classes.iconWrapper}>
              <img
                src={amount}
                alt='bao-cao.svg'
                className={classes.reportIcon}
              />
            </div>
            <span>Manage connect tokens</span>
          </NavLink>

          <div className={classes.heading}>SafeMoon Homepage</div>
          <div className={classes.divider} />

          <ExpandMenus
            menu={{
              image: faq,
              label: 'FAQ',
              value: 'FAQ',
              iconStyle: classes.reportIcon,
            }}
            subMenus={[
              {
                label: 'Category',
                value: 'Category',
                href: '/admin/faq/category',
              },
              {
                label: 'Faq',
                value: 'Faq',
                href: '/admin/faq/list',
              },
            ]}
            screen={pathname.indexOf('/admin/faq') !== -1 ? 'FAQ' : screen}
            collapse={collapse}
            openMenus={openMenus}
            handleToggleMenu={this.handleToggleMenu}
          />

          <div className={classes.heading}>SafeMoon Admin</div>
          <div className={classes.divider} />

          <NavLink
            to='/admin/users'
            className={classNames(classes.menuItem)}
            activeClassName={classes.active}
          >
            <div className={classes.iconWrapper}>
              <img
                src={userIcon}
                alt='quan-ly-khach-hang.svg'
                className={classes.userIcon}
              />
            </div>
            <span>Users</span>
          </NavLink>

          {/* <div className={classes.heading}>Settings</div>
          <div className={classes.divider} />

          <NavLink
            to='/admin/settings'
            className={classNames(classes.menuItem)}
            activeClassName={classes.active}
          >
            <div className={classes.iconWrapper}>
              <img
                src={settingsIcon}
                alt='bao-cao.svg'
                className={classes.reportIcon}
              />
            </div>
            <span>Setting params</span>
          </NavLink> */}
        </div>
      </PerfectScrollbar>
      // </Menu>
    )
  }
}
