import React from 'react'
import cancelIcon from 'images/cancel.svg'
import editIcon from 'images/edit.svg'
import classNames from 'classnames'
import ButtonApprove from 'components/ButtonApprove'
import ButtonReject from 'components/ButtonReject'
import SwitchField from 'components/SwitchField'
import classes from './TableAction.module.scss'

const TableAction = ({
  handleRemove,
  handleEdit,
  handleCopy,
  handleApprove,
  handleReject,
  handleChangeStatus,
  status,
  direction,
  handleViewDetail,
  size,
  item,
  handleNote,
  More,
}) => (
  <div
    className={classNames(
      'tbAction',
      direction === 'col' && classes.col,
      size === 'lg' && classes.containerLg,
    )}
  >
    {handleCopy && (
      <a className={classes.btnCopy} onClick={handleCopy}>
        Copy
      </a>
    )}
    {handleViewDetail && (
      <a className={classes.btnCopy} onClick={() => handleViewDetail(item)}>
        Detail
      </a>
    )}
    {handleRemove && (
      <a className='btnRemove' onClick={() => handleRemove(item)}>
        <img src={cancelIcon} className='removeIcon' alt='remove' />
      </a>
    )}
    {handleEdit && (
      <a className='btnEdit' onClick={() => handleEdit(item)}>
        <img src={editIcon} alt='edit' />
      </a>
    )}

    {handleApprove && (
      <ButtonApprove
        onClick={() => handleApprove(item)}
        btnStyle={classNames(size === 'lg' && classes.btnLg)}
      />
    )}
    {handleReject && (
      <ButtonReject
        onClick={() => handleReject(item)}
        btnStyle={classNames(size === 'lg' && classes.btnLg)}
      />
    )}
    {handleChangeStatus && (
      <div className={classes.ml10}>
        <SwitchField
          input={{
            value: status,
            onChange: (active) => handleChangeStatus(active, item),
          }}
        />
      </div>
    )}
    {handleNote && (
      <a className={classes.btnNote} onClick={() => handleNote(item)}>
        <img src={editIcon} alt='edit' />
        <span>Note</span>
      </a>
    )}
    {More && More(item)}
  </div>
)

export default TableAction
