// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonApprove_container__38lTB {\n  display: flex;\n  align-items: center;\n  padding: 8px 16px;\n  background-color: #3fb550;\n  border-radius: 6px;\n  border: 0;\n  margin-left: 10px;\n  font-size: 12px;\n  font-weight: bold;\n  color: #ffffff;\n  min-width: 110px; }\n  .ButtonApprove_container__38lTB .ButtonApprove_backIcon__insiP {\n    width: 16px;\n    margin-right: 4px; }\n\n.ButtonApprove_customLoader__3deUu {\n  position: absolute;\n  right: 6px;\n  top: 50%;\n  transform: translateY(-50%); }\n", "",{"version":3,"sources":["webpack://src/components/ButtonApprove/ButtonApprove.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,SAAS;EACT,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,gBAAgB,EAAA;EAXlB;IAaI,WAAW;IACX,iBAAiB,EAAA;;AAMrB;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,2BAA2B,EAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  padding: 8px 16px;\n  background-color: #3fb550;\n  border-radius: 6px;\n  border: 0;\n  margin-left: 10px;\n  font-size: 12px;\n  font-weight: bold;\n  color: #ffffff;\n  min-width: 110px;\n  .backIcon {\n    width: 16px;\n    margin-right: 4px;\n  }\n\n  \n}\n\n.customLoader {\n  position: absolute;\n  right: 6px;\n  top: 50%;\n  transform: translateY(-50%);   \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ButtonApprove_container__38lTB",
	"backIcon": "ButtonApprove_backIcon__insiP",
	"customLoader": "ButtonApprove_customLoader__3deUu"
};
export default ___CSS_LOADER_EXPORT___;
