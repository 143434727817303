// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CopyButton_copyIcon__3lZxs {\n  width: 20px; }\n\n.CopyButton_container__3hKnk {\n  position: relative; }\n", "",{"version":3,"sources":["webpack://src/components/CopyButton/CopyButton.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAA;;AAGb;EACE,kBAAkB,EAAA","sourcesContent":[".copyIcon {\n  width: 20px;\n}\n\n.container {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyIcon": "CopyButton_copyIcon__3lZxs",
	"container": "CopyButton_container__3hKnk"
};
export default ___CSS_LOADER_EXPORT___;
