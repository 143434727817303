import React, { useState, useRef } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Overlay, Tooltip } from 'react-bootstrap'
import copyIcon from 'images/copy.svg'
import classes from './CopyButton.module.scss'

const CopyButton = ({ text }) => {
  const target = useRef(null)
  const [showTooltip, setShowTooltip] = useState(false)
  return (
    <div className={classes.container}>
      <CopyToClipboard
        text={text}
        onCopy={() => {
          setShowTooltip(true)
          setTimeout(() => {
            setShowTooltip(false)
          }, 3000)
        }}
      >
        <img
          src={copyIcon}
          className={classes.copyIcon}
          alt='copyIcon'
          ref={target}
        />
      </CopyToClipboard>
      <Overlay target={target.current} show={showTooltip} placement='right'>
        {(props) => (
          <Tooltip id='overlay-example' {...props}>
            Copied!
          </Tooltip>
        )}
      </Overlay>
    </div>
  )
}

export default CopyButton
