// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sort_container__1v_ac {\n  display: inline-block;\n  margin-left: 10px; }\n  .Sort_container__1v_ac .Sort_icon__35GqR {\n    font-size: 13px;\n    opacity: 0.3; }\n  .Sort_container__1v_ac .Sort_iconRight__2FZJg {\n    font-size: 13px;\n    margin-left: 4px;\n    opacity: 0.3; }\n  .Sort_container__1v_ac .Sort_active__AkgjZ {\n    opacity: 1; }\n", "",{"version":3,"sources":["webpack://src/components/Sort/Sort.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,iBAAiB,EAAA;EAFnB;IAOI,eAAe;IACf,YAAY,EAAA;EARhB;IAWI,eAAe;IACf,gBAAgB;IAChB,YAAY,EAAA;EAbhB;IAiBI,UAAU,EAAA","sourcesContent":[".container {\n  display: inline-block;\n  margin-left: 10px;\n  // position: absolute;\n  // right: 2px;\n  // top: 18px;\n  .icon {\n    font-size: 13px;\n    opacity: 0.3;\n  }\n  .iconRight {\n    font-size: 13px;\n    margin-left: 4px;\n    opacity: 0.3;\n  }\n\n  .active {\n    opacity: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Sort_container__1v_ac",
	"icon": "Sort_icon__35GqR",
	"iconRight": "Sort_iconRight__2FZJg",
	"active": "Sort_active__AkgjZ"
};
export default ___CSS_LOADER_EXPORT___;
