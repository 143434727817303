import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isLoggedIn } from 'utils/utils'

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isLoggedIn()) {
        const url = '/admin/users'
        return <Redirect to={url} />
      }
      return <Component {...rest} {...props} />
    }}
  />
)

export default AuthRoute
