export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount'
export const DAEMON = '@@saga-injector/daemon'
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount'

export const STATUS = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Inactive',
    value: 'INACTIVE',
  },
]

export const STATUS_OBJ = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
}

export const SYMBOLS = [
  {
    value: '',
    label: 'All Tokens',
  },
  {
    value: 'BNB',
    label: 'BNB',
  },
  {
    value: 'SAFEMOON',
    label: 'SAFEMOON',
  },
  {
    value: 'BUSD',
    label: 'BUSD',
  },
  {
    value: 'CAKE',
    label: 'CAKE',
  },
  {
    value: 'TWT',
    label: 'TWT',
  },
  {
    value: 'SUSHI',
    label: 'SUSHI',
  },
  {
    value: 'USDT',
    label: 'USDT',
  },
  {
    value: 'USDC',
    label: 'USDC',
  },
  {
    value: 'LINK',
    label: 'LINK',
  },
  {
    value: 'MIOTA',
    label: 'MIOTA',
  },
  {
    value: 'EOS',
    label: 'EOS',
  },
  {
    value: 'DAI',
    label: 'DAI',
  },
  {
    value: 'UNI',
    label: 'UNI',
  },
  {
    value: 'AAVE',
    label: 'AAVE',
  },
  {
    value: 'WBNB',
    label: 'WBNB',
  },
  {
    value: 'NBL',
    label: 'NBL',
  },
  {
    value: 'AQUAGOAT',
    label: 'AQUAGOAT',
  },
  {
    value: 'BabyDoge',
    label: 'BabyDoge',
  },
  {
    value: 'ElonGate',
    label: 'ElonGate',
  },
  {
    value: 'MCONTENT',
    label: 'MCONTENT',
  },
  {
    value: 'USELESS',
    label: 'USELESS',
  },
  {
    value: 'AMP',
    label: 'AMP',
  },
  {
    value: 'AXS',
    label: 'AXS',
  },
  {
    value: 'CEL',
    label: 'CEL',
  },
  {
    value: 'CRO',
    label: 'CRO',
  },
  {
    value: 'MANA',
    label: 'MANA',
  },
  {
    value: 'ENJ',
    label: 'ENJ',
  },
  {
    value: 'ETH',
    label: 'ETH',
  },
  {
    value: 'GRT',
    label: 'GRT',
  },
  {
    value: 'HEX',
    label: 'HEX',
  },
  {
    value: 'POLY',
    label: 'POLY',
  },
  {
    value: 'PSAFEMOON',
    label: 'PSAFEMOON',
  },
  {
    value: 'SHIB',
    label: 'SHIB',
  },
]

export const LISTING_APPLICATION_STATUS = [
  {
    label: 'Waiting for review',
    value: 'WAITING',
  },
  {
    label: 'Requested Additional Information',
    value: 'REQUEST_ADDITION_INFOMATION',
  },
  {
    label: 'Review additional information',
    value: 'SUBMITTED_ADITIONAL',
  },
  {
    label: 'Approved',
    value: 'COMPLETED',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
]

export const LISTING_APPLICATION_STATUS_OBJ = {
  WAITING: 'Waiting for review',
  COMPLETED: 'Approved',
  REJECTED: 'Rejected',
  SUBMITTED_ADITIONAL: 'Review additional information',
  REQUEST_ADDITION_INFOMATION: 'Requested Additional Information',
}

export const FACTORY_ADDRESS = {
  eth: process.env.REACT_APP_ETH_FACTORY_ADDRESS,
  bsc: process.env.REACT_APP_BSC_FACTORY_ADDRESS,
}
