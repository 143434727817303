module.exports = {
  // production
  /*
  API_URL: 'https://dashboard.safemoon.net/api',
  SOCKET_URL: 'https://dashboard.safemoon.net',
  SOCKET_PATH: '/chat-socket',
  MARKET_DATA_API: 'https://marketdata.safemoon.net',
  FileURL: 'https://dashboard.safemoon.net/api/server/image',
  Bep20ChainId: 56,
  Erc20ChainId: 1,
  BINANCE_RPC_URL: 'https://bsc-dataseed.binance.org',
  ETHER_RPC_URL:
    'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161', */
  // dev
  API_URL: 'https://dashboarddev.safemoon.net/api',
  SOCKET_URL: 'https://dashboarddev.safemoon.net',
  SOCKET_PATH: '/chat-socket',
  MARKET_DATA_API: 'https://marketdata.safemoon.net',
  FileURL: 'https://dashboarddev.safemoon.net/api/server/image',
  Bep20ChainId: 97,
  Erc20ChainId: 3,
  /*
  API_URL: 'http://localhost:3222/api',
  SOCKET_URL: 'http://localhost:3223',
  SOCKET_PATH: '/chat-socket',
  MARKET_DATA_API: 'http://localhost:8001',
  FileURL: 'http://localhost:3222/api/server/image', */

  BINANCE_RPC_URL: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
  ETHER_RPC_URL:
    'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',

  API_TIMEOUT: 300000,
  ETH_SUBGRAPH_URL: process.env.REACT_APP_ETH_MAINNET_SUBGRAPH_URL,
  BSC_SUBGRAPH_URL: process.env.REACT_APP_BSC_MAINNET_SUBGRAPH_URL,

  ETH_BLOCK_EXPLORER_URL: process.env.REACT_APP_ETH_BLOCK_EXPLORER_URL,
  BSC_BLOCK_EXPLORER_URL: process.env.REACT_APP_BSC_BLOCK_EXPLORER_URL,
}
