// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonReject_container__1aIbf {\n  display: flex;\n  align-items: center;\n  padding: 8px 16px;\n  background-color: #ffffff;\n  border-radius: 6px;\n  border: 1px solid #dddddd;\n  margin-left: 10px;\n  font-size: 12px;\n  font-weight: bold;\n  color: #ef4d1d;\n  min-width: 110px; }\n  .ButtonReject_container__1aIbf .ButtonReject_backIcon__1ZYif {\n    width: 16px;\n    margin-right: 4px; }\n", "",{"version":3,"sources":["webpack://src/components/ButtonReject/ButtonReject.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,gBAAgB,EAAA;EAXlB;IAaI,WAAW;IACX,iBAAiB,EAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  padding: 8px 16px;\n  background-color: #ffffff;\n  border-radius: 6px;\n  border: 1px solid #dddddd;\n  margin-left: 10px;\n  font-size: 12px;\n  font-weight: bold;\n  color: #ef4d1d;\n  min-width: 110px;\n  .backIcon {\n    width: 16px;\n    margin-right: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ButtonReject_container__1aIbf",
	"backIcon": "ButtonReject_backIcon__1ZYif"
};
export default ___CSS_LOADER_EXPORT___;
