// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdminPage_head__1VPY8 {\n  display: flex;\n  align-items: center;\n  justify-content: space-between; }\n  .AdminPage_head__1VPY8 .AdminPage_title__VcqZ3 {\n    font-size: 18px;\n    font-weight: bold;\n    color: #aaaaaa;\n    margin-bottom: 0; }\n    @media (max-width: 767px) {\n      .AdminPage_head__1VPY8 .AdminPage_title__VcqZ3 {\n        padding: 0 20px; } }\n\n.AdminPage_filter__NimnV {\n  margin-bottom: 20px;\n  width: 100%; }\n", "",{"version":3,"sources":["webpack://src/components/AdminPage/AdminPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B,EAAA;EAHhC;IAKI,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,gBAAgB,EAAA;IAChB;MATJ;QAUM,eAAe,EAAA,EAElB;;AAGH;EACE,mBAAmB;EACnB,WAAW,EAAA","sourcesContent":[".head {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  .title {\n    font-size: 18px;\n    font-weight: bold;\n    color: #aaaaaa;\n    margin-bottom: 0;\n    @media (max-width: 767px) {\n      padding: 0 20px;\n    }\n  }\n}\n\n.filter {\n  margin-bottom: 20px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head": "AdminPage_head__1VPY8",
	"title": "AdminPage_title__VcqZ3",
	"filter": "AdminPage_filter__NimnV"
};
export default ___CSS_LOADER_EXPORT___;
