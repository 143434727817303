export const GET_WALLETADDRESS_BLACKLIST =
  'admin/WalletAddressBlacklist/getWalletAddressBlacklist'
export const GET_WALLETADDRESS_BLACKLIST_SUCCESS =
  'admin/WalletAddressBlacklist/getWalletAddressBlacklistSuccess'
export const GET_WALLETADDRESS_BLACKLIST_FAILURE =
  'admin/WalletAddressBlacklist/getWalletAddressBlacklistFailure'

export const getWalletAddressBlacklist = () => ({
  type: GET_WALLETADDRESS_BLACKLIST,
})

export const getWalletAddressBlacklistSuccess = (data) => ({
  type: GET_WALLETADDRESS_BLACKLIST_SUCCESS,
  data,
})

export const getWalletAddressBlacklistFailure = () => ({
  type: GET_WALLETADDRESS_BLACKLIST_FAILURE,
})

export const initialState = {
  data: {},
  loading: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_WALLETADDRESS_BLACKLIST:
      return {
        ...state,
        loading: true,
      }
    case GET_WALLETADDRESS_BLACKLIST_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      }
    case GET_WALLETADDRESS_BLACKLIST_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
