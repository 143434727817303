/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { IntlProvider } from 'react-intl'
import { makeSelectLocale } from './selectors'
import messages from '../../messages/messages.json'

export function LanguageProvider({ locale, children }) {
  return (
    <IntlProvider
      locale={locale}
      messages={messages[locale]}
      defaultLocale={locale}
    >
      {React.Children.only(children)}
    </IntlProvider>
  )
}

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

const mapStateToProps = createSelector(makeSelectLocale(), (locale) => ({
  locale,
}))

export default connect(mapStateToProps)(LanguageProvider)
