export const UPDATE_CONVERSATIONS = 'app/chatStore/updateConversations'
export const UPDATE_CONVERSATION = 'app/chatStore/updateConversation'

export const updateConversations = (conversations) => ({
  type: UPDATE_CONVERSATIONS,
  conversations,
})

export const updateConversation = (conversation) => ({
  type: UPDATE_CONVERSATION,
  conversation,
})

export const initialState = {
  conversations: [],
}

export default function chatReducers(state = initialState, action) {
  let newConversations = []
  let findConversation
  switch (action.type) {
    case UPDATE_CONVERSATIONS:
      return {
        ...state,
        conversations: action.conversations,
      }
    case UPDATE_CONVERSATION:
      findConversation = state.conversations.find(
        (conver) => conver._id === action.conversation._id,
      )
      if (findConversation) {
        return state
      }
      if (state.conversations.length >= 5) {
        newConversations = [
          action.conversation,
          ...state.conversations.slice(0, 4),
        ]
      } else {
        newConversations = [action.conversation, ...state.conversations]
      }
      return {
        ...state,
        conversations: newConversations,
      }
    default:
      return state
  }
}
