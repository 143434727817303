import React, { Component } from 'react'
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts'
import { formatStringToNumber } from 'utils/utils'
import classes from './ReportDevices.module.scss'

export default class ReportDevices extends Component {
  render() {
    const { devices } = this.props
    const data = [
      { value: devices?.android },
      { value: devices?.ios },
      { value: devices?.browser },
    ]
    const COLORS = ['#2DBC60', '#f4b400', '#5EAC42']

    return (
      <div className={classes.container}>
        <p className={classes.title}>Device in system</p>

        <div className={classes.chartWrapper}>
          <ResponsiveContainer>
            <PieChart onMouseEnter={this.onPieEnter}>
              <Pie
                data={data}
                innerRadius={100}
                outerRadius={150}
                fill='#8884d8'
                paddingAngle={5}
                dataKey='value'
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className={classes.legends}>
          <div className={classes.legend}>
            <div className={classes.traded} />
            <div className={classes.right}>
              <p className={classes.name}>Android devices</p>
              <p className={classes.volumn}>
                {formatStringToNumber(devices?.android)} devices
              </p>
            </div>
          </div>

          <div className={classes.legend}>
            <div className={classes.remain} />
            <div className={classes.right}>
              <p className={classes.name}>IOS devices</p>
              <p className={classes.volumn}>
                {formatStringToNumber(devices?.ios)} devices
              </p>
            </div>
          </div>
          {/* <div className={classes.legend}>
            <div className={classes.browser} />
            <div className={classes.right}>
              <p className={classes.name}>Browser</p>
              <p className={classes.volumn}>{devices.browser} browser</p>
            </div>
          </div> */}
        </div>
      </div>
    )
  }
}
