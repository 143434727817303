// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoadingIndicator_loading__3LMiJ {\n  text-align: center; }\n", "",{"version":3,"sources":["webpack://src/components/LoadingIndicator/LoadingIndicator.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB,EAAA","sourcesContent":[".loading {\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "LoadingIndicator_loading__3LMiJ"
};
export default ___CSS_LOADER_EXPORT___;
