import React from 'react'
import Select, { components } from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import {
  faCheck,
  faCaretUp,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons'
import classes from './MultiSelectOptionsField.module.scss'

const Icon = ({ selectProps }) => (
  <FontAwesomeIcon
    icon={selectProps.menuIsOpen ? faCaretUp : faCaretDown}
    className={classes.icon}
  />
)

const MenuList = (props) => {
  const { children } = props
  return (
    <div className={classes.menuList}>
      <components.MenuList {...props}>{children}</components.MenuList>
    </div>
  )
}

const Option = (props) => {
  const { isSelected, label } = props
  return (
    <div className={classes.filterGroup}>
      <components.Option {...props}>
        <div className={classes.filterOption}>
          <input
            type='checkbox'
            checked={isSelected}
            onChange={() => null}
            className={classes.checkbox}
          />
          <span className={classes.checkboxCustom}>
            <FontAwesomeIcon icon={faCheck} className={classes.checkIcon} />
          </span>
          <label className={classes.label}> {label} </label>
        </div>
      </components.Option>
    </div>
  )
}

class MultiSelectOptionsField extends React.Component {
  onChange = (value) => {
    const { input } = this.props
    input.onChange(value)
  }

  render() {
    const { options, input, placeholder, name } = this.props
    return (
      <Select
        // menuIsOpen
        // controlShouldRenderValue={false}
        className={classNames(classes.input, 'selectField')}
        onChange={this.onChange}
        components={{ Option, MenuList, DropdownIndicator: Icon }}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        options={options}
        isMulti
        placeholder={placeholder}
        value={input.value}
        instanceId={name}
        styles={{
          control: () => ({
            border: 0,
            padding: 0,
            margin: '2px 0 0 0px',
          }),
          option: (styles) => ({
            ...styles,
            backgroundColor: '#1d1d1d !important;',
            '&:hover': {
              backgroundColor: 'rgb(52, 58, 63) !important',
            },
          }),
          indicatorsContainer: () => ({
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: 8,
            display: 'flex',
            alignItems: 'center',
          }),
          indicatorSeparator: () => ({}),
          loadingIndicator: () => ({
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: '25px',
            width: '45px',
            fontSize: '6px',
            color: '#ffffff',
          }),
          menu: (styles) => ({
            ...styles,
            backgroundColor: '#1d1d1d',
          }),
          valueContainer: (styles) => ({
            ...styles,
            flexWrap: 'nowrap',
            marginRight: '50px',
          }),
          multiValue: (styles) => ({
            ...styles,
            backgroundColor: '#343a3f',
            '&>div': {
              color: '#ffffff !important',
            },
          }),
        }}
      />
    )
  }
}

export default MultiSelectOptionsField
