import React from 'react'
import TableAction from 'components/TableAction'
import DoubleScrollbar from 'react-double-scrollbar'
import classNames from 'classnames'
import Sort from 'components/Sort'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import classes from './Table.module.scss'

const Table = ({ tableOptions, data, filter, handleSearch, isReorder }) => (
  <div className={classNames('table-responsive table')}>
    <DoubleScrollbar>
      <table className='table table-borderless'>
        <thead>
          <tr className='tbHead'>
            {tableOptions.map((option) => (
              <th
                key={option.name}
                onClick={() => {
                  if (option.allowSort) {
                    handleSearch({
                      sort: option.name,
                      sortType:
                        filter?.sortType === 'ASC' &&
                        option.name === filter.sort
                          ? 'DESC'
                          : 'ASC',
                    })
                  }
                }}
              >
                {option.label}
                {option.allowSort && (
                  <Sort
                    active={filter?.sort === option.name}
                    sortType={filter?.sortType}
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <Droppable droppableId='table'>
          {(droppableProvided) => (
            <tbody
              ref={(ref) => {
                // this.tableRef = ref
                droppableProvided.innerRef(ref)
              }}
              {...droppableProvided.droppableProps}
            >
              {data &&
                data.map((item, i) => (
                  <Draggable
                    draggableId={item._id || `${i + 1}`}
                    index={i}
                    key={item._id || i}
                    isDragDisabled={!isReorder}
                  >
                    {(provided, snapshot) => (
                      <tr
                        key={item.id || item._id || i}
                        className={classNames(
                          'tbRow',
                          snapshot.isDragging && classes.isDragging,
                        )}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {tableOptions.map((option) => (
                          <td key={option.name}>
                            {option.type === 'ACTION' ? (
                              <TableAction
                                handleApprove={option.handleApprove}
                                handleReject={option.handleReject}
                                handleEdit={option.handleEdit}
                                handleViewDetail={option.handleViewDetail}
                                handleRemove={option.handleRemove}
                                status={option.status === item.status}
                                handleChangeStatus={option.handleChangeStatus}
                                handleNote={option.handleNote}
                                handleCopy={option.handleCopy}
                                direction={option.direction}
                                size={option.size}
                                item={item}
                                More={option.More}
                              />
                            ) : (
                              <>
                                {option.renderCol
                                  ? option.renderCol(item)
                                  : item[option.name]}
                              </>
                            )}
                          </td>
                        ))}
                      </tr>
                    )}
                  </Draggable>
                ))}
              {droppableProvided.placeholder}
            </tbody>
          )}
        </Droppable>
      </table>
    </DoubleScrollbar>
  </div>
)

export default Table
