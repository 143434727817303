import React, { useState } from 'react'
import Table from 'components/Table'
import classNames from 'classnames'
import { displayAddress } from 'utils/utils'
import { DragDropContext } from 'react-beautiful-dnd'
import CopyButton from 'components/CopyButton'
import { useDispatch } from 'react-redux'
import {
  handleShowConfirm,
  showNotification,
} from 'layout/CommonLayout/actions'
import { Modal } from 'react-bootstrap'
import { renderField } from 'Form'
import { Bep20ChainId, Erc20ChainId } from 'utils/config'
import classes from './TokenField.module.scss'
import TokenForm from '../TokenForm'

const TokensField = ({ input }) => {
  const [selectedToken, setSelectedToken] = useState()
  const dispatch = useDispatch()

  const onDragEnd = (result) => {
    // dropped outside the list
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return
    }
    const newLsData = [...input.value]
    const oldData = newLsData.splice(result.source.index, 1)
    newLsData.splice(result.destination.index, 0, oldData[0])
    console.log(newLsData)
    input.onChange(newLsData)
  }

  const handleEdit = (item) => {
    setSelectedToken(item)
  }

  const handleSubmitToken = (values) => {
    if (selectedToken?.address) {
      input.onChange(
        input.value.map((token) => {
          if (token.address === values.address) {
            return { ...values, logoURI: values.logoURI?.url }
          }
          return token
        }),
      )
      setSelectedToken(null)
    } else {
      const findToken = input.value.find(
        (token) => token.address === values.address,
      )
      if (findToken) {
        dispatch(
          showNotification({
            type: 'ERROR',
            message: 'Address is existed!',
          }),
        )
      } else {
        input.onChange([
          ...input.value,
          { ...values, logoURI: values.logoURI?.url },
        ])
        setSelectedToken(null)
      }
    }
  }

  const handleRemove = (item) => {
    dispatch(
      handleShowConfirm({
        title: 'Confirm',
        description: 'Do you want remove this token?',
        handleOk: () => {
          const newValue = input.value.filter(
            (token) => token.address !== item.address,
          )
          input.onChange(newValue)
        },
      }),
    )
  }

  const handleChangeStatus = (enable, item) => {
    if (enable) {
      dispatch(
        handleShowConfirm({
          title: 'Confirm',
          description:
            'Are you sure you want to publish this pair to live swap apps?',
          handleOk: () => {
            const newValue = input.value.map((token) => {
              if (token.address === item.address) {
                return {
                  ...item,
                  status: enable ? 'ENABLE' : 'DISABLE',
                }
              }
              return token
            })
            input.onChange(newValue)
          },
        }),
      )
    } else {
      const newValue = input.value.map((token) => {
        if (token.address === item.address) {
          return {
            ...item,
            status: enable ? 'ENABLE' : 'DISABLE',
          }
        }
        return token
      })
      input.onChange(newValue)
    }
  }

  const handleAddLiquidity = (item) => {
    window.open(`${window.location.origin}/liquidity/#/add/${item.address}/ETH`)
  }

  const tableOptions = [
    {
      name: 'logoURI',
      label: 'Token image',
      renderCol: (item) => (
        <img src={item.logoURI} className={classes.logoURI} alt='logo' />
      ),
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'chainId',
      label: 'Chain ID',
    },
    {
      name: 'decimals',
      label: 'Decimals',
    },
    {
      name: 'address',
      label: 'Address',
      renderCol: (item) => (
        <div className={classes.row}>
          <span>{displayAddress(item.address)}</span>{' '}
          <CopyButton text={item.address} />
        </div>
      ),
    },
    {
      type: 'ACTION',
      handleEdit,
      handleRemove,
      name: 'action',
      handleChangeStatus,
      status: 'ENABLE',
      More: (item) => (
        <div>
          {item.status !== 'COMPLETED' && (
            <a
              onClick={() => {
                handleAddLiquidity(item)
              }}
              className='btn btnTiny btnSecond ml10'
            >
              Add liquidity
            </a>
          )}
        </div>
      ),
    },
  ]

  return (
    <div className={classes.container}>
      <div className={classes.rowBetween}>
        <h3 className={classes.subTitle}>Tokens</h3>
        <div className={classes.row}>
          <a
            className='btn btnSecond mr10'
            onClick={() => setSelectedToken({ chainId: Erc20ChainId })}
          >
            Add ERC_20 Token
          </a>
          <a
            className='btn btnSecond'
            onClick={() => setSelectedToken({ chainId: Bep20ChainId })}
          >
            Add BEP_20 Token
          </a>
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={classNames('table-responsive table')}>
          <Table data={input.value} isReorder tableOptions={tableOptions} />
        </div>
      </DragDropContext>

      <Modal
        show={!!selectedToken}
        onHide={() => setSelectedToken(null)}
        size='md'
      >
        <TokenForm
          selectedToken={selectedToken}
          handleClose={() => setSelectedToken(null)}
          handleSubmitToken={handleSubmitToken}
          initialValues={
            selectedToken?.address
              ? {
                  ...selectedToken,
                  logoURI: {
                    url: selectedToken.logoURI,
                  },
                }
              : selectedToken
          }
        />
      </Modal>
    </div>
  )
}

export default renderField(TokensField)
