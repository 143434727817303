import React, { useState, useMemo } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { formatStringToNumber } from 'utils/utils'
import TabField from 'components/TabField'
import classes from './BarChart.module.scss'

const BarChartComponent = ({ title, data, handleGetChains }) => {
  const [day, setDay] = useState(7)
  const [viewType, setViewType] = useState('NUMBER_OF_TRANSFER')

  const dataChart = useMemo(
    () => (viewType === 'NUMBER_OF_TRANSFER' ? data.data : data.dataUSD),
    [data, viewType],
  )

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.title}>{title}</div>
        <div className={classes.rowBetween}>
          <div className={classes.type}>
            <TabField
              options={[
                {
                  label: 'Number of Transfer',
                  value: 'NUMBER_OF_TRANSFER',
                },
                {
                  label: 'USD',
                  value: 'USD',
                },
              ]}
              value={viewType}
              changeValue={(val) => {
                setViewType(val)
              }}
            />
          </div>
          <div className={classes.date}>
            <TabField
              options={[
                {
                  label: '7 days',
                  value: 7,
                },
                {
                  label: '14 days',
                  value: 14,
                },
                {
                  label: '30 days',
                  value: 30,
                },
              ]}
              value={day}
              changeValue={(val) => {
                setDay(val)
                handleGetChains(val)
              }}
            />
          </div>
        </div>
        <div className={classes.chartWrapper}>
          <ResponsiveContainer>
            <BarChart
              data={dataChart}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid stroke='#707070' vertical={false} />
              <XAxis
                dataKey='name'
                tickLine={false}
                tick={{ fill: '#f8f8f8' }}
              />
              <YAxis
                tickFormatter={(value) => `${formatStringToNumber(value)}`}
                axisLine={false}
                tick={{ fill: '#f8f8f8' }}
              />
              <Tooltip />
              <Bar dataKey='BEP20' stackId='a' fill='#2DBC60' barSize={24} />
              <Bar dataKey='ERC20' stackId='b' fill='#f4b400' barSize={24} />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className={classes.legends}>
          <div className={classes.legend}>
            <div className={classes.buyer} />
            <p className={classes.name}>BEP 20</p>
          </div>
          <div className={classes.legend}>
            <div className={classes.seller} />
            <p className={classes.name}>ERC 20</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BarChartComponent
