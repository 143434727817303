import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import AuthRoute from 'routes/AuthRoute'
import logo from 'images/logo.svg'
import ChangePassword from '../../pages/ChangePassword'
import ForgotPassword from '../../pages/ForgotPassword'
import Login from '../../pages/Login'
import classes from './AuthLayout.module.scss'

const AuthLayout = () => {
  const { path } = useRouteMatch()

  return (
    <div className={classes.container}>
      <img src={logo} className={classes.logo} alt='logo' />
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <div className={classes.screen}>
            <Switch>
              <AuthRoute path={`${path}/login`} component={Login} />
              <AuthRoute
                path={`${path}/forgot-password`}
                component={ForgotPassword}
              />
              <AuthRoute
                path={`${path}/change-password/:token`}
                component={ChangePassword}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthLayout
