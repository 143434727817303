import React, { useMemo, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Modal } from 'react-bootstrap'
import { handleShowConfirm } from 'layout/CommonLayout/actions'
import { useFilter } from 'hooks/useFilter'
import AdminPage from 'components/AdminPage'
import { convertSearchParamsToObject, deleteAccents } from 'utils/utils'
import * as Api from 'api/api'
import moment from 'moment'
import { useInjectReducer } from '../../../utils/injectReducer'
import { useInjectSaga } from '../../../utils/injectSaga'
import classes from './TokenBlacklist.module.scss'
import saga from './saga'
import { makeSelectData } from './selectors'
import reducer, { getTokenBlacklist } from './slices'
import Form from './components/Form'

const mapStateToProps = createStructuredSelector({
  tokenBlacklistData: makeSelectData(),
})

const filterOptions = [
  {
    type: 'SEARCH',
    name: 'textSearch',
    label: 'Search...',
  },
]

const TokenBlacklist = ({ location }) => {
  /**
   * inject saga và reducer vào hệ thống
   * */
  useInjectSaga({ key: 'tokenBlacklist', saga })
  useInjectReducer({ key: 'tokenBlacklist', reducer })

  /**
   * state
   */
  const [showForm, setShowForm] = useState(false)
  const [selectedToken, setSelectedToken] = useState()

  const [data, setData] = useState()

  /**
   * get data
   */
  const getData = () => {}

  const convertQueryToFilter = (query) => ({
    textSearch: query.textSearch || '',
  })

  const { filter, handleSearch } = useFilter({
    location,
    getData,
    convertQueryToFilter,
  })

  const { tokenBlacklistData } = useSelector(mapStateToProps)

  useEffect(() => {
    dispatch(getTokenBlacklist())
  }, [])

  useEffect(() => {
    if (tokenBlacklistData && tokenBlacklistData.docs?.length > 0) {
      const params = convertSearchParamsToObject(location.search)

      const newResults = tokenBlacklistData.docs.filter(
        (item) =>
          !params.textSearch ||
          deleteAccents(`${item.tokenContractAddress} ${item.blockReason}`)
            .toLowerCase()
            .indexOf(
              deleteAccents(decodeURI(params.textSearch)).toLowerCase(),
            ) !== -1,
      )

      setData({
        ...tokenBlacklistData,
        docs: newResults,
      })
    } else {
      setData({
        ...tokenBlacklistData,
        docs: [],
      })
    }
  }, [location.search, tokenBlacklistData])

  const dispatch = useDispatch()

  const handleDeleteData = (item) => {
    dispatch(
      handleShowConfirm({
        title: 'Confirm',
        description: 'Do you delete this token blacklist?',
        handleOk: async () => {
          try {
            await Api.marketData.deleteData({
              url: `/api/swap/token-blacklist`,
              params: {
                id: item._id,
              },
            })

            handleRefreshData()
          } catch (e) {
            return Promise.reject(e)
          }
        },
      }),
    )
  }

  const handleRefreshData = () => {
    const params = convertSearchParamsToObject(location.search)
    dispatch(getTokenBlacklist(params))
  }

  const handleShowEdit = (item) => {
    setSelectedToken(item)
    setShowForm(true)
  }

  const tableOptions = useMemo(
    () => [
      {
        label: 'Blocked date',
        name: 'createdAt',
        renderCol: (item) =>
          moment(item.createdAt).format('MMM, DD YYYY HH:mm'),
      },
      {
        name: 'tokenContractAddress',
        label: 'Token contract address',
      },
      {
        name: 'blockReason',
        label: 'Block reason',
      },
      {
        name: 'blocker',
        label: 'Blocker',
        renderCol: (item) => item.blocker.email,
      },
      {
        type: 'ACTION',
        name: 'action',
        handleRemove: handleDeleteData,
        handleEdit: handleShowEdit,
      },
    ],
    [],
  )

  return (
    <div className={classes.container}>
      <AdminPage
        data={data}
        filter={filter}
        handleSearch={handleSearch}
        filterOptions={filterOptions}
        tableOptions={tableOptions}
        handleAddNew={() => {
          setSelectedToken(null)
          setShowForm(true)
        }}
      />

      <Modal show={showForm} onHide={() => setShowForm(false)} size='md'>
        <Form
          initialValues={selectedToken || {}}
          handleClose={() => setShowForm(false)}
          handleRefreshData={handleRefreshData}
          selectedToken={selectedToken}
        />
      </Modal>
    </div>
  )
}

export default TokenBlacklist
