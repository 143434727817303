// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Filter_container__3PDVc {\n  display: flex;\n  flex-flow: row wrap; }\n  .Filter_container__3PDVc .Filter_option__2Bpjr {\n    margin-right: 15px; }\n    .Filter_container__3PDVc .Filter_option__2Bpjr:last-child {\n      margin-right: 0; }\n  .Filter_container__3PDVc .Filter_search__1rO5B {\n    width: 350px; }\n  .Filter_container__3PDVc .Filter_select__2G5Gq {\n    width: 200px; }\n  .Filter_container__3PDVc .Filter_multiSelect__1C0Zp {\n    width: 400px; }\n  @media (max-width: 767px) {\n    .Filter_container__3PDVc {\n      flex-direction: column; }\n      .Filter_container__3PDVc .Filter_option__2Bpjr, .Filter_container__3PDVc .Filter_search__1rO5B, .Filter_container__3PDVc .Filter_select__2G5Gq {\n        margin-right: 0;\n        width: 100%;\n        margin-bottom: 10px; } }\n", "",{"version":3,"sources":["webpack://src/components/Filter/Filter.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB,EAAA;EAFrB;IAII,kBAAkB,EAAA;IAJtB;MAMM,eAAe,EAAA;EANrB;IAUI,YAAY,EAAA;EAVhB;IAcI,YAAY,EAAA;EAdhB;IAiBI,YAAY,EAAA;EAGd;IApBF;MAqBI,sBAAsB,EAAA;MArB1B;QAuBM,eAAe;QACf,WAAW;QACX,mBAAmB,EAAA,EACpB","sourcesContent":[".container {\n  display: flex;\n  flex-flow: row wrap;\n  .option {\n    margin-right: 15px;\n    &:last-child {\n      margin-right: 0;\n    }\n  }\n  .search {\n    width: 350px;\n  }\n\n  .select {\n    width: 200px;\n  }\n  .multiSelect {\n    width: 400px;\n  }\n\n  @media (max-width: 767px) {\n    flex-direction: column;\n    .option, .search, .select {\n      margin-right: 0;\n      width: 100%;\n      margin-bottom: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Filter_container__3PDVc",
	"option": "Filter_option__2Bpjr",
	"search": "Filter_search__1rO5B",
	"select": "Filter_select__2G5Gq",
	"multiSelect": "Filter_multiSelect__1C0Zp"
};
export default ___CSS_LOADER_EXPORT___;
