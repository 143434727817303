// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Button_btn__1Lkv5 {\n  background-color: transparent; }\n  .Button_btn__1Lkv5:hover, .Button_btn__1Lkv5:active, .Button_btn__1Lkv5:visited, .Button_btn__1Lkv5:focus {\n    outline: 0; }\n\n.Button_btn__1Lkv5 {\n  position: relative;\n  display: flex; }\n  .Button_btn__1Lkv5 .Button_loader__3tdiH {\n    margin-left: 6px; }\n\n.Button_disabled__1f6YP {\n  pointer-events: none;\n  opacity: 0.5; }\n\n.Button_eventNone__3rLQ3 {\n  pointer-events: none; }\n", "",{"version":3,"sources":["webpack://src/components/Button/Button.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAA6B,EAAA;EAD/B;IAGI,UAAU,EAAA;;AAId;EACE,kBAAkB;EAClB,aAAa,EAAA;EAFf;IAII,gBAAgB,EAAA;;AAIpB;EACE,oBAAoB;EACpB,YAAY,EAAA;;AAGd;EACE,oBAAoB,EAAA","sourcesContent":[".btn {\n  background-color: transparent;\n  &:hover, &:active, &:visited, &:focus {\n    outline: 0;\n  }\n}\n\n.btn {\n  position: relative;\n  display: flex;\n  .loader {\n    margin-left: 6px;\n  }\n}\n\n.disabled {\n  pointer-events: none;\n  opacity: 0.5;\n}\n\n.eventNone {\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "Button_btn__1Lkv5",
	"loader": "Button_loader__3tdiH",
	"disabled": "Button_disabled__1f6YP",
	"eventNone": "Button_eventNone__3rLQ3"
};
export default ___CSS_LOADER_EXPORT___;
