import React, { useState, useMemo } from 'react'
import {
  ComposedChart,
  Line,
  Bar,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { formatStringToNumber } from 'utils/utils'
import { SelectField } from 'components/SelectField/SelectField'
import TabField from 'components/TabField'
import { SYMBOLS } from 'utils/constants'
import classes from './ChartWrapper.module.scss'

const ChartWrapper = ({ handleGetToken, data }) => {
  const [day, setDay] = useState(7)

  const [symbol, setSymbol] = useState('')

  const [viewType, setViewType] = useState('NUMBER_OF_TRANSFER')

  const [chartType, setChartType] = useState('LINE_CHART')

  const chartData = useMemo(() => {
    const newData = viewType === 'NUMBER_OF_TRANSFER' ? data.data : data.dataUSD
    return newData ? newData[symbol && symbol.value ? symbol.value : 'ALL'] : []
  }, [data, symbol, viewType])

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <p className={classes.title}>Report by: </p>
        <div className={classes.type}>
          <TabField
            options={[
              {
                label: 'Number of Transfer',
                value: 'NUMBER_OF_TRANSFER',
              },
              {
                label: 'USD',
                value: 'USD',
              },
            ]}
            value={viewType}
            changeValue={(val) => {
              setViewType(val)
            }}
          />
        </div>
      </div>

      <div className={classes.rowBetween}>
        <div className={classes.selectWrapper}>
          <SelectField
            input={{
              value: symbol,
              onChange: (val) => {
                setSymbol(val)
              },
            }}
            options={SYMBOLS}
            placeholder='Select token'
          />
        </div>
        <div className={classes.between}>
          <TabField
            options={[
              {
                label: 'Line chart',
                value: 'LINE_CHART',
              },
              {
                label: 'Area chart',
                value: 'AREA_CHART',
              },
              {
                label: 'Bar chart',
                value: 'BAR_CHART',
              },
            ]}
            value={chartType}
            changeValue={(val) => {
              setChartType(val)
            }}
          />
        </div>
        <div className={classes.date}>
          <TabField
            options={[
              {
                label: '7 days',
                value: 7,
              },
              {
                label: '14 days',
                value: 14,
              },
              {
                label: '30 days',
                value: 30,
              },
            ]}
            value={day}
            changeValue={(val) => {
              setDay(val)
              handleGetToken(val)
            }}
          />
        </div>
      </div>

      <div className={classes.chartWrapper}>
        <ResponsiveContainer>
          <ComposedChart
            data={chartData}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 5,
            }}
          >
            <CartesianGrid stroke='#aaaaaa' vertical={false} />
            <XAxis
              dataKey='name'
              padding={{ top: 10 }}
              tickLine={false}
              tick={{ fill: '#f8f8f8' }}
            />
            <YAxis
              dataKey='val'
              yAxisId='left'
              orientation='left'
              // label={{ value: 'Lô', angle: 0, position: 'insideBottomLeft' }}
              tickFormatter={(value) => `${formatStringToNumber(value)}`}
              axisLine={false}
              tick={{ fill: '#f8f8f8' }}
            />
            <Tooltip />
            {chartType === 'LINE_CHART' && (
              <Line
                type='basic'
                dataKey='val'
                stroke='#f4b400'
                yAxisId='left'
                strokeWidth={2}
                dot
                isAnimationActive
              />
            )}
            {chartType === 'BAR_CHART' && (
              <Bar dataKey='val' barSize={30} fill='#f4b400' yAxisId='left' />
            )}
            {chartType === 'AREA_CHART' && (
              <Area
                type='monotone'
                dataKey='val'
                stroke='#f4b400'
                fill='#cbdde9'
                yAxisId='left'
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default ChartWrapper
