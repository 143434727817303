export const GET_TOKEN_BLACKLIST = 'admin/TokenBlacklist/getTokenBlacklist'
export const GET_TOKEN_BLACKLIST_SUCCESS =
  'admin/TokenBlacklist/getTokenBlacklistSuccess'
export const GET_TOKEN_BLACKLIST_FAILURE =
  'admin/TokenBlacklist/getTokenBlacklistFailure'

export const getTokenBlacklist = () => ({
  type: GET_TOKEN_BLACKLIST,
})

export const getTokenBlacklistSuccess = (data) => ({
  type: GET_TOKEN_BLACKLIST_SUCCESS,
  data,
})

export const getTokenBlacklistFailure = () => ({
  type: GET_TOKEN_BLACKLIST_FAILURE,
})

export const initialState = {
  data: {},
  loading: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TOKEN_BLACKLIST:
      return {
        ...state,
        loading: true,
      }
    case GET_TOKEN_BLACKLIST_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      }
    case GET_TOKEN_BLACKLIST_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
