import React from 'react'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/InputField'
import Button from 'components/Button'
import closeIcon from 'images/close.svg'
import classes from './ConfirmSave.module.scss'

const ConfirmSave = ({ handleClose, handleSubmit, handleSave }) => {
  const handleConfirm = async () => {
    handleSave()
    handleClose()
  }

  return (
    <form className={classes.container} onSubmit={handleSubmit(handleConfirm)}>
      <div className={classes.head}>
        <h2 className={classes.title}>Confirm</h2>
        <a className={classes.btnClose} onClick={handleClose}>
          <img src={closeIcon} className={classes.closeIcon} alt='closeIcon' />
        </a>
      </div>
      <div className={classes.content}>
        <Field
          name='text'
          component={InputField}
          label='Enter "Confirm" to save'
          placeholder='Confirm'
          required
        />
        <Button className='btn btnMain w100' type='submit'>
          Submit
        </Button>
      </div>
    </form>
  )
}

const validate = (values) => {
  const errors = {}

  if (values.text !== 'Confirm') {
    errors.text = 'Please enter "Confirm" to save'
  }

  return errors
}

export default reduxForm({
  form: 'ConfirmSave',
  validate,
  enableReinitialize: true,
})(ConfirmSave)
