import { put, takeLatest, call } from 'redux-saga/effects'
import * as Api from 'api/api'
import {
  getWalletAddressBlacklistSuccess,
  getWalletAddressBlacklistFailure,
  GET_WALLETADDRESS_BLACKLIST,
} from './slices'

export function* getWalletAddressBlacklist() {
  try {
    const result = yield call(Api.marketData.get, {
      url: '/api/swap/walletaddress-blacklist',
    })

    yield put(
      getWalletAddressBlacklistSuccess({
        docs: result.data,
        page: 1,
        totalElements: result.data.length,
        totalPages: 1,
      }),
    )
  } catch (e) {
    yield put(getWalletAddressBlacklistFailure())
  }
}

export default function* saga() {
  yield takeLatest(GET_WALLETADDRESS_BLACKLIST, getWalletAddressBlacklist)
}
