import { ApolloClient, InMemoryCache } from '@apollo/client'
import {
  ETH_SUBGRAPH_URL,
  BSC_SUBGRAPH_URL,
  BSC_BLOCK_EXPLORER_URL,
  ETH_BLOCK_EXPLORER_URL,
} from 'utils/config'

export const ETHMainnetSubgraphClient = new ApolloClient({
  uri: ETH_SUBGRAPH_URL,
  cache: new InMemoryCache(),
})

export const BSCMainnetSubgraphClient = new ApolloClient({
  uri: BSC_SUBGRAPH_URL,
  cache: new InMemoryCache(),
})

export const ETHBlockExplorerClient = new ApolloClient({
  uri: ETH_BLOCK_EXPLORER_URL,
  cache: new InMemoryCache(),
})

export const BSCBlockExplorerClient = new ApolloClient({
  uri: BSC_BLOCK_EXPLORER_URL,
  cache: new InMemoryCache(),
})

export const HealthClient = new ApolloClient({
  uri: 'https://api.thegraph.com/index-node/graphql',
  cache: new InMemoryCache(),
})

export const factoryClient = {
  BSC: BSCMainnetSubgraphClient,
  ETH: ETHMainnetSubgraphClient,
}

export const blockClient = {
  BSC: BSCBlockExplorerClient,
  ETH: ETHBlockExplorerClient,
}

export default {
  factory: factoryClient,
  block: blockClient,
  health: HealthClient,
}
